import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { Link } from "gatsby"
import getSlug from "../../../utils/getSlug"

const LogoSVG = styled.img`
	position: absolute;
	top: 90px;
	left: 35px;
	width: calc(100% - 70px);
	z-index: 2;
	svg path{
		fill: white;
	}
	@media (min-width: 370px) {
		width: 300px;
	}
	@media (min-width: 1000px) {
		top: 60px;
		left: 65px;
		width: 360px;
	}
	
	&.fixed {	
		@media (min-width: 920px) {
			position: fixed;
		}
	}
`

class Logo extends React.Component {

	render() {
		const { logoData, isLogoWhite, activeSite } = this.props
		const logoUrl = isLogoWhite ? (logoData.logoBiale && logoData.logoBiale[0].url) : (logoData.logo && logoData.logo[0].url) || ""
		const slug = (logoData.logoLink && logoData.logoLink[0] && logoData.logoLink[0].slug)

		return (
			<Link to={`/${getSlug(slug, activeSite)}`}>
				<LogoSVG id="logo" src={logoUrl}/>
			</Link>
		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(Logo)
