import React from "react"
import Header from "./Header"
import Description from "./Description"
import Photo from "./Photo"
import Citation from "./Citation"
import NewsColumns from "./NewsColumns"

const PageContentExtendedContent = (props) => {

	const { data } = props || {}

	return (
		data.map((block, i) => {
			switch (block.__typename) {
				case "Craft_trescPodstrony_naglowek_BlockType":
					return <Header key={i} data={block}/>
				case "Craft_trescPodstrony_opis_BlockType":
					return <Description key={i} data={block}/>
				case "Craft_trescPodstrony_zdjecieSzerokie_BlockType":
					return <Photo key={i} data={block}/>
				case "Craft_trescPodstrony_cytat_BlockType":
					return <Citation key={i} data={block}/>
				case "Craft_trescPodstrony_kolumny_BlockType":
					return <NewsColumns key={i} data={block}/>
				case "Craft_trescPodstrony_zdjecie_BlockType":
					return <Photo key={i} data={block}/>
				default:
					return <h1 key={i}>Brak obsługi dla {block.__typename}</h1>
			}

		})
	)
}

export default PageContentExtendedContent
