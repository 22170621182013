import React from "react"
import styled from "styled-components"

export const Paragraphs = styled.p`

	font-size: ${({ theme }) => theme.paragraphs.p2};
	color: ${({ theme }) => theme.colors.stringWhite};
	font-weight: ${({ theme }) => theme.font.thin};
	line-height: 28px;
	font-family: ${ ({ theme }) => theme.font.textFont };
	
	&.font-size-p1 {
		font-size: ${({ theme }) => theme.paragraphs.p1};
	}
	&.font-size-p2 {
		font-size: ${({ theme }) => theme.paragraphs.p2};
	}
	&.font-size-p3 {
		font-size: ${({ theme }) => theme.paragraphs.p3};
	}
	&.font-size-p4 {
		font-size: ${({ theme }) => theme.paragraphs.p4};
	}
	&.font-size-p5 {
		font-size: ${({ theme }) => theme.paragraphs.p5};
	}
	&.font-size-p6 {
		font-size: ${({ theme }) => theme.paragraphs.p6};
	}
	
	
	&.font-family-subheader {
		font-family: ${ ({ theme }) => theme.font.headerFont };
	}
	
	
	&.color-white {
		color: ${({ theme }) => theme.colors.stringWhite};
	}
	&.color-black {
		color: ${({ theme }) => theme.colors.stringBlack};
	}
	&.color-blue {
		color: ${({ theme }) => theme.colors.stringBlue}; 
	}

	&.font-weight-thin {
		font-weight: ${({ theme }) => theme.font.thin};
	}
	&.font-weight-light {
		font-weight: ${({ theme }) => theme.font.light};
	}
	&.font-weight-regular {
		font-weight: ${({ theme }) => theme.font.regular};
	}
	&.font-weight-medium {
		font-weight: ${({ theme }) => theme.font.medium};
	}
	&.font-weight-semiBold {
		font-weight: ${({ theme }) => theme.font.semiBold};
	}
	&.font-weight-bold {
		font-weight: ${({ theme }) => theme.font.bold};
	}
	&.font-weight-extraBold {
		font-weight: ${({ theme }) => theme.font.extraBold};
	}
	&.font-weight-black {
		font-weight: ${({ theme }) => theme.font.black};
	}
	
	&.line-height-10 {
		line-height: ${({ theme }) => theme.lineHeight.line10};
	}
	&.line-height-11 {
		line-height: ${({ theme }) => theme.lineHeight.line11};
	}
`

export default Paragraphs;
