import React from "react"
import styled from "styled-components"


const NewsInfo = styled.div`
	color:  ${({ theme }) => theme.colors.stringBlack};
	font-size: ${({ theme }) => theme.paragraphs.p4};
	line-height: ${({ theme }) => theme.lineHeight.line16};
	font-weight: ${({ theme }) => theme.font.bold};
	position: relative;
	
	&.date{
		&:before {
			content: '';
			width: 8px;
   		height: 8px;
   		position: absolute;
   		top: -20px;
   		left: 0;
   		background-color: ${({ theme }) => theme.colors.stringBlack};
		}
	}
`


class SingleNewsAuthorDataInfo extends React.Component {
	render() {
		const { dateInfo, author} = this.props;

		return (
			<>
				<NewsInfo className="date">{dateInfo}</NewsInfo>
				<NewsInfo>{author}</NewsInfo>
			</>
		)
	}
}

export default SingleNewsAuthorDataInfo
