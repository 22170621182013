import React from "react"
import styled from "styled-components"

const ContentWrapperColumn = styled.div`
		display: flex;
		flex-flow: column wrap;
`;

export const ContentWrapperRow = styled(ContentWrapperColumn)`
	flex-flow: row;
`;

export default ContentWrapperColumn
