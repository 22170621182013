import React from "react"
import styled from "styled-components"

const FooterHeader = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.headers.h7};
	color: ${({ theme }) => theme.colors.stringWhite};

	@media (max-width: 800px){
		padding: 0 0 0 0
	};
`

const FooterBoxesSocialMedia = styled.div`
	padding: 0 0 45px 0;
	line-height: 77px;
	min-width: 150px;
	height: auto;

`

const ULLIstSocialMedia = styled.div`
	display: flex;
	flex-flow: row;
	font-weight: ${({ theme }) => theme.font.regular};
	color: ${({ theme }) => theme.colors.stringWhite};
	list-style-typne: none;
	padding: 0;
	margin: 0;
	line-height: 2;

	@media (max-width: 800px){
		padding: 0 0 0 0
	};
`

const ListItemLinkedIn = styled.a`
	width: 26px;
	height: 26px;
	margin: 0 10px 0 0;
`

const ListItemTwitter = styled.a`
	width: 26px;
	height: 26px;
	margin: 0 10px 0 0;
`

const Image = styled.img`
	width: 100%;
	height: 100%;
`

class ColumnFour extends React.Component {
	render() {
		const socialMediaHeader = this.props.socialMediaHeaderData
		const footerSocialMedia = this.props.columnFourData
		const linkedinIcon = (footerSocialMedia.socialMedia && footerSocialMedia.socialMedia[0].socialMediaLogo && footerSocialMedia.socialMedia[0].socialMediaLogo[0].url) || ""
		const linkedinIconUrl = (footerSocialMedia.socialMedia && footerSocialMedia.socialMedia[0].socialMediaLink && footerSocialMedia.socialMedia[0].socialMediaLink) || ""

		const twitterIcon = (footerSocialMedia.socialMedia && footerSocialMedia.socialMedia[1] && footerSocialMedia.socialMedia[1].socialMediaLogo && footerSocialMedia.socialMedia[1].socialMediaLogo[0].url) || ""

		const twitterIconUrl = (footerSocialMedia.socialMedia && footerSocialMedia.socialMedia[1] && footerSocialMedia.socialMedia[1].socialMediaLink && footerSocialMedia.socialMedia[1].socialMediaLink) || ""

		return (
			<FooterBoxesSocialMedia>
				<FooterHeader>{socialMediaHeader}</FooterHeader>
				<ULLIstSocialMedia>
					<ListItemLinkedIn href={linkedinIconUrl} target="_blank">
						<Image src={linkedinIcon} alt={linkedinIcon[0].altText}/>
					</ListItemLinkedIn>
					{twitterIcon && <ListItemTwitter href={twitterIconUrl} target="_blank">
						<Image src={twitterIcon} alt={twitterIcon[0].altText}/>
					</ListItemTwitter>}
				</ULLIstSocialMedia>
			</FooterBoxesSocialMedia>
		)
	}
}

export default ColumnFour
