import React from "react"
import styled from "styled-components"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Link } from "gatsby"
import getSlug from "../../utils/getSlug"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

const CookiesBarWrapper = styled.div`
	.cookies-bar-style {
	padding-bottom: 20px !important;
	@media (min-width: 1000px){
    padding: 0 !important;
  }
	}
	.cookies-btn-style {    
		:before {
			content: '';
			position: absolute;
			bottom: -3px;
			right: 15px;
			width: 12px;
			height: 3px;
			background-color: rgba(0, 0, 0 ,.7);
			transition: margin 0.5s linear;
		}
		
		:hover {
			outline: none;
			background-color: white !important;
			color: ${({ theme }) => theme.colors.stringBlack} !important;
			:before{
				margin-right: calc(100% - 40px) !important;
			}
		}
}
`

const CookiesInfoLink = styled(Link)`
	padding-left: 5px;
	color: ${({ theme }) => theme.colors.stringWhite};
`

class CookiesBar extends React.Component {
	render() {
		const { cookies, activeSite } = this.props
		const cookiesData = (cookies && cookies[0]) || ""
		const cookiesBarText = cookiesData.cookiesTresc || ""
		const cookiesBtnText = cookiesData.cookiesPrzycisk || ""
		const cookiesKnowMore = (cookiesData && cookiesData.cookiesDowiedzSieWiecej && cookiesData.cookiesDowiedzSieWiecej[0]) || ""
		const cookiesLinkText = cookiesKnowMore.dowiedzSieWiecejTekst || ""
		const cookiesLinkSlug = cookiesKnowMore && cookiesKnowMore.dowiedzSieWiecejLink && cookiesKnowMore.dowiedzSieWiecejLink[0] && cookiesKnowMore.dowiedzSieWiecejLink[0].slug || ""
		const textLinkTo = getSlug(cookiesLinkSlug, activeSite)


		return (
			<CookiesBarWrapper>
				<CookieConsent
					onAccept={() => {
							Cookies.set('gatsby-gdpr-google-analytics', true);
							const location = window.location.href;
							initializeAndTrack(location);
					}}
					location="bottom"
					buttonText={cookiesBtnText}
					cookieName="gatsby-gdpr-google-tagmanager"
					containerClasses="cookies-bar-style"
					style={{
						background: "rgba(0, 0, 0 ,.7)",
						zIndex: "1002",
						fontWeight: 600,
						fontSize: "12px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "70px",
					}}
					buttonClasses="cookies-btn-style"
					buttonStyle={{
						position: "relative",
						backgroundColor: "transparent",
						minWidth: "185px",
						borderRadius: "7px",
						border: "3px solid white",
						color: "white",
						fontWeight: "bold",
						fontSize: "16px",
						margin: 0,
						padding: "10px 0",
						textTransform: "uppercase",
					}}
					contentStyle={{
						flex: "none",
						maxWidth: "calc(100vw - 40px)",
						textAlign: "center",
					}}
					expires={150}
				>
				{cookiesBarText}
					<CookiesInfoLink to={`/${textLinkTo}`}>{cookiesLinkText}</CookiesInfoLink>
				</CookieConsent>
			</CookiesBarWrapper>
		)
	}
}

export default CookiesBar
