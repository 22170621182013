import React from "react"
import styled from "styled-components"
import Headers from "../common/headers/Headers"
import Paragraphs from "../common/paragraphs/Paragraphs"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"

const BannerWrapper = styled.div`
  padding: 235px 0 0 0;

  @media (min-width: 1000px){
  	  padding: 300px 0 190px;
  }
`

const BannerContent = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
	width: 100vw;
	position: relative;
	padding: 0 35px;

	@media (min-width: 1000px) {
		flex-direction: row;
		justify-content: flex-start;
		padding: 0 140px;
	}
`

const TextWrapper = styled.div`
	position: relative;
`

const MainHeader = styled(Headers)`
	font-size: ${({ theme }) => theme.headers.h6};
	font-weight: ${({ theme }) => theme.font.bold};
	position: relative;	
	display: table;
  z-index: 1;
  padding: 0 31px 0 0;
  color: ${({ theme }) => theme.colors.stringBlue}; 
	
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		width: 8px;
		height: 8px;
		right: -10px;
		background-color: ${({ theme }) => theme.colors.stringBlue}; 
  }
  
  @media (min-width: 1000px){
    font-size: ${({ theme }) => theme.headers.h2};
  }
`

const Description = styled(Paragraphs)`
  font-weight: ${({ theme }) => theme.font.light};
  font-size: ${({ theme }) => theme.paragraphs.p1};
	padding: 30px 0 100px;
  width: 90%;
	
	@media (min-width: 1000px) {
		padding: 30px 0;
		font-size: ${({ theme }) => theme.paragraphs.p1};
		max-width: 490px;
	}
`

class PrivacyPolicyBanner extends React.Component {

	render() {
		const bannerData = this.props.data && this.props.data.banerTekstowy || []
		const header = bannerData && bannerData[0] && bannerData[0].naglowek || ""
		const description = bannerData && bannerData[0] && bannerData[0].opis || ""

		return (
			<BannerWrapper>
				<BannerContent>
					<TextWrapper>
						<MainHeader>{header}</MainHeader>
						<Description className="color-blue font-weight-regular font-family-subheader"
												 dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
					</TextWrapper>
				</BannerContent>
			</BannerWrapper>
		)
	}
}

export default PrivacyPolicyBanner
