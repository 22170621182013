import React from "react"
import styled from "styled-components"
import ContentWrapperColumn from "../common/pageContainer/ContentContainer"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import PageContainer from "../common/pageContainer/PageContainer"

const Content = styled.header`
		background-color: ${({ theme }) => theme.colors.stringWhite};
		min-height: 564px;
		margin: 0;
		display: flex; 
		justify-content: flex-end;
		align-items: center;
		
		@media (max-width: 800px){
				min-height: 555px;
		}
`;

const ContentWhitekWrapper = styled(ContentWrapperColumn)`
		background-color: ${({ theme }) => theme.colors.stringWhite};
		// max-width: 100vw;
		margin: 0;
		// padding: 155px 0 166px 0;
		
		display: flex;
		justify-content: space-between;
		flex-flow: row;
		
		@media (max-width: 970px){
				min-height: 555px;
				width: 100%;
				justify-content: flex-start;
				padding: 78px 0 78px 0;
		}
`;
const Header = styled.h2`
		font-weight: ${({ theme }) => theme.font.bold};
		font-size: ${({ theme }) => theme.headers.h2};
		padding: 0 0 45px 0;
		width: 50%;
		line-height: 77px;
		
		@media (max-width: 800px){
				font-size: ${({ theme }) => theme.headers.h6};
				padding: 0 0 83px 0;
				line-height: 50px;
		};


`;
const Text = styled.div`
		font-weight: ${({ theme }) => theme.font.light};
		font-size: ${({ theme }) => theme.paragraphs.p2};
		color: black;

		@media (max-width: 800px){
				padding: 0 0 0 0
		};
`;

	const Paragraph = styled.div`
		width:65%;
		font-weight: ${({ theme }) => theme.font.light};
		font-size: ${({ theme }) => theme.paragraphs.p2};
		color: ${({ theme }) => theme.colors.stringBlack};
		line-height: 28px;

		@media (max-width: 800px){
			width: 100%;
		}
`;
class HeaderAndDescription extends React.Component {
		render(){
				const aboutData = this.props.data.naglowekIOpis;
				const aboutDataArray = []

			aboutData.forEach(item => {
				aboutDataArray.push({
					"header": item && item.naglowek,
					"title": item.opis,
				})
			})
				return(
				<Content>
						<PageContainer>
								<ContentWhitekWrapper>
									{aboutDataArray.map((item, key) =>
										<Paragraph key={key} className="font-size-p2 font-weight-light color-black">
											<Header className="font-size-h2">{item.header}</Header>
											<Text dangerouslySetInnerHTML={getHtmlFromRedactorField(item.title)} />
										</Paragraph>
									)}
								</ContentWhitekWrapper>

						</PageContainer>
				</Content>

				)
		}
}

export default HeaderAndDescription
