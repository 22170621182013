import dayjs from 'dayjs';
require('dayjs/locale/pl');


const getDateInProperFormat = (activeSite, createdDate, monthAsString) => {

	if(!monthAsString) {
		if (activeSite === "default") {
			return dayjs(createdDate).locale('pl').format('D.MM.YYYY');
		} else {
			return dayjs(createdDate).locale('en').format('D.MM.YYYY');

		}
	} else {
		if (activeSite === "default") {
			return dayjs(createdDate).locale('pl').format('D MMMM YYYY');
		} else {
			return dayjs(createdDate).locale('en').format('D MMMM YYYY');
		}
	}
}

export default getDateInProperFormat

