import React from "react"
import styled from "styled-components"
import getSingleImage from "../../utils/getSingleImage"
import { connect } from "react-redux"
import { changeLogoColor } from "../../state/app"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"


const BannerWrapper = styled.div`
	height: 100vh;
	background-image: url(${props => props.back});
	background-position: center;
	background-size: cover;
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-color: ${({ theme }) => theme.colors.stringBlack};
		opacity: .4;
		width: 100%;
		height: 100%;
	}
`

const BannerDescription = styled.div`
	position: absolute;
	left: 0;
	bottom: 100px;
	padding: 0 35px;
	color: ${({ theme }) => theme.colors.stringWhite};
	line-height: ${({ theme }) => theme.lineHeight.line14};
	font-size: ${({ theme }) => theme.fontSizes.f13};
	
	@media (min-width: 1000px) {
		margin-left: 140px;
		padding: 0;
		bottom: 215px;
		font-size: ${({ theme }) => theme.fontSizes.f10};
		line-height: ${({ theme }) => theme.lineHeight.line13};
		width: 65%;
	}
`

class SingleNewsBanner extends React.Component {
	render() {
		const { dispatch } = this.props
		dispatch(changeLogoColor(true))
		const data = (this.props.data) || []
		const bannerPhoto = (data && data[0] && data[0].zdjecie) || ""
		const bannerDescription = (data && data[0] && data[0].opis) || ""

		return (
			<BannerWrapper back={getSingleImage(bannerPhoto)}>
				<BannerDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(bannerDescription)}/>
			</BannerWrapper>

		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(SingleNewsBanner)
