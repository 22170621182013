const manageScroll = (state) => {
	if (typeof window !== "undefined") {
		if (state) {
			document.documentElement.style.overflowY = "hidden"
			document.body.style.position = "fixed"
		} else {
			document.documentElement.style.overflowY = ""
			document.body.style.position = ""
		}
	}
}

export default manageScroll
