import React, { Component } from "react"
import posed, { PoseGroup } from "react-pose"
import styled from "styled-components"
import ButtonBlueBorderWhite from "../buttons/Button"


const PosedModal = posed.div({
	enter: {
		opacity: 1,
		y: 0,
		x: 0,
		delay: 150,
		transition: {
			y: { type: "spring", stiffness: 500, damping: 30 },
			default: { duration: 300 },
		},
	},
	exit: {

		opacity: 0,
		x: 0,
		y: 1000,
		transition: { duration: 150 },

	},
})

const PosedShade = posed.div({
	enter: { opacity: .95 },
	exit: { opacity: 0 },
})

const StyledPosedShade = styled(PosedShade)`
  z-index: 1010;
  background: ${({ theme }) => theme.colors.stringBlack};
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`

const StyledPosedModal = styled(PosedModal)`
  text-align: center;
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledModalWrapper = styled.div`
   width: 320px;
   height: 330px;
   text-align: left;
   padding: 35px;

  @media(min-width: 768px){
    width: 440px;
    height: 310px;
  }
`

const ModalHeader = styled.h1`
  font-size: ${({ theme }) => theme.headers.h6};
  font-weight:${({ theme }) => theme.font.bold};
  color:  ${({ theme }) => theme.colors.stringWhite};

  @media(min-width: 1000px){
  font-size: ${({ theme }) => theme.headers.h4};
  }
`

const ModalInfo = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.f13};
  color: ${({ theme }) => theme.colors.stringWhite};
  font-weight: ${({ theme }) => theme.font.light};
  padding-top: 35px;
`

const ButtonBlueBorderWhiteExtended = styled(ButtonBlueBorderWhite)`
	margin-top: 60px;
	border: 3px solid ${({ theme }) => theme.colors.stringLightBlue};
	color: ${({ theme }) => theme.colors.stringLightBlue};
	
	&:before {
		background-color: ${({ theme }) => theme.colors.stringBlack};
	}
	
	&:hover {
		border: 3px solid ${({ theme }) => theme.colors.stringWhite};
	}
`

class Popup extends Component {

	constructor(props) {
		super(props)
		this.escFunction = this.escFunction.bind(this)
	}

	escFunction(event) {
		if (this.props.isVisible && event.keyCode === 27) {
			this.props.hide()
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false)
	}

	render() {
		const { hide, isVisible, modalConfirmData } = this.props
		const modalData = (modalConfirmData && modalConfirmData.modalPotwierdzenieWyslania) || []
		const header = (modalData && modalData[0] && modalData[0].naglowek) || ""
		const description = (modalData && modalData[0] && modalData[0].opis) || ""
		const textBtn = (modalData && modalData[0] && modalData[0].przyciskOpis) || ""

		return (
			<PoseGroup>
				{isVisible && [
					<StyledPosedShade key="shade" className="shade"/>,
					<StyledPosedModal key="modal" className="modal">
						<StyledModalWrapper>
							<ModalHeader>{header}</ModalHeader>
							<ModalInfo>{description}</ModalInfo>
							<ButtonBlueBorderWhiteExtended onClick={hide}>
								<span>{textBtn}</span>
							</ButtonBlueBorderWhiteExtended>
						</StyledModalWrapper>
					</StyledPosedModal>
				]}
			</PoseGroup>
		)
	}
}

export default Popup
