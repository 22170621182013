import React from "react"
import styled from "styled-components"
import { StyledLinkOnPicture } from "../common/buttons/ButtonLinkStyle"
import getSingleImage from "../../utils/getSingleImage"
import getSlug from "../../utils/getSlug"
import { ButtonWhiteBlackBackground } from "../common/buttons/Button"
import ButtonOnPictureNews from "../common/buttons/ButtonsOnPictureNews"


const BuyWrapper = styled.div`
  background-image: url(${props => props.back});
  background-repeat: no-repeat;
  background-size: cover;
  color:  ${({ theme }) => theme.colors.stringWhite};
  position: relative;
  padding: 75px 35px 100px;
  
  &:before {
  	content: '';
  	position: absolute;
  	top: 0;
  	left: 0;
  	background-color: black;
  	opacity: .3;
  	width: 100%;
  	height: 100%;
  }
  
  @media (min-width: 1000px) {
		min-height: 700px;
		padding: 165px 140px 205px;
	}
  
`
const BuyHeader = styled.div`
	font-size: ${({ theme }) => theme.headers.h6};
	font-weight: ${({ theme }) => theme.font.black};
	position: relative;	
	width: fit-content;
	z-index: 1;
	margin-bottom: 30px;
	
	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.headers.h1h};
		font-weight: ${({ theme }) => theme.font.semiBold};
		margin-bottom: 0;
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		width: 8px;
		height: 8px;
		right: -10px;
		background-color: ${({ theme }) => theme.colors.stringWhite};
	
		@media (min-width: 1000px) {
			width: 23px;
			height: 23px;
			right: -80px;
		}
	}
`

const BuyDescription = styled.div`
	font-size: ${({ theme }) => theme.paragraphs.p2};
	position: relative;
	margin-bottom: 50px;
	
	@media (min-width: 1000px) {
		padding-top: 30px;
		font-size: ${({ theme }) => theme.paragraphs.p1};
		margin-bottom: 95px;
	}
`

const BuyButton = styled(ButtonWhiteBlackBackground)`

  :before{
    background-color: #F3F3F3;
  }
  :hover{
    background-color: ${({ theme }) => theme.colors.stringBlue};
    color: ${({ theme }) => theme.colors.stringWhite};
  }
  :hover :before{
    background-color: ${({ theme }) => theme.colors.stringWhite};
  }

`
class ProductBuy extends React.Component {

	render() {
		const activeSite = this.props.activeSite
		const data = this.props.data.produktKupno && this.props.data.produktKupno[0] || []
		const header = data.naglowek || ""
		const description = data.opis || ""
		const buttonDescription = data.przyciskOpis || ""
		const backgroundImg = data.zdjecie || ""
		const buttonSlug = (data.przyciskLink && data.przyciskLink[0] && data.przyciskLink[0].slug) || ""

		return (
			<BuyWrapper back={ getSingleImage(backgroundImg)} >
				<BuyHeader>{header}</BuyHeader>
				<BuyDescription>{description}</BuyDescription>
				<StyledLinkOnPicture to={`/${getSlug(buttonSlug, activeSite)}`}>
					<ButtonOnPictureNews readTextBtn={buttonDescription}/>
				</StyledLinkOnPicture>
			</BuyWrapper>
		)
	}
}

export default ProductBuy
