import React from "react"
import styled from "styled-components"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import Headers from "../common/headers/Headers"
import posed from "react-pose"
import { LinkButton } from "../common/buttons/ButtonLinkStyle"
import ButtonBlueBorderWhite from "../common/buttons/Button"

const ProductSpecWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.stringBlue};
	padding: 45px 35px 70px;
	
	@media (min-width: 1000px){
		padding: 45px 140px 70px;
	}
		
	.Dropdown-root {
		@media (min-width: 1000px){
			display: flex;
			justify-content: flex-end;
		}
	}
	
	.customControl {
		background-color: transparent;
		border: 2px solid ${({ theme }) => theme.colors.stringWhite};
		border-radius: 7px;
		height: 60px;
		padding: 0;
		font-size: ${({ theme }) => theme.fontSizes.f14};
		color: ${({ theme }) => theme.colors.stringWhite};
		font-weight: ${({ theme }) => theme.font.bold};
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: visible; 
		
		&:before {
			content: '';
			position: absolute;
			bottom: -4px;
			width: 12px;
			height: 12px;
			right: 20px;
			background-color: ${({ theme }) => theme.colors.stringBlue};
		}
		
		@media (min-width: 1000px){
			width: 220px;
		}
	}
	
	.customMenu {
		background-color: ${({ theme }) => theme.colors.stringBlue};
		font-size: ${({ theme }) => theme.fontSizes.f14};
		font-weight: ${({ theme }) => theme.font.bold};
		border: 2px solid ${({ theme }) => theme.colors.stringWhite};
		border-radius: 7px;
		padding: 0;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		overflow: visible;

		&:before {
			content: '';
			position: absolute;
			top: -6px;
			width: 12px;
			height: 12px;
			right: 20px;
			background-color: ${({ theme }) => theme.colors.stringBlue};
		}
		
		@media (min-width: 1000px){
			width: 220px;
		}
		.Dropdown-option {
			color: ${({ theme }) => theme.colors.stringWhite};
			padding: 40px 0;
			
			&:hover {
				background-color: transparent;
				color: ${({ theme }) => theme.colors.stringWhite};
			}
		}
		.is-selected {
		background-color: transparent;
		}
	}
	
	.customArrow {
		border-color: white transparent transparent; 
		top: 24px;
		
		&.hidden {
			visibility: hidden;
		}
	}
	
	.is-open {
		.Dropdown-arrow {
			border-color: transparent transparent white; 
		}
	}
`

const PosedExpanded = posed.tbody({
	visible: {
		height: "auto",
		y: 0,
		opacity: 1,
		delay: 0,
		transition: {
			y: { ease: "easeOut", duration: 250 },
			default: { duration: 200 },
		},
	},
	hidden: {
		padding: 0,
		height: 0,
		y: -20,
		opacity: 0,
		delay: 0,
		transition: { duration: 150 },
	},
})

const RowExtended = styled(PosedExpanded)`
		overflow: hidden;
		display: block;
`

const TableStyled = styled.table`
	margin-top: 45px;
	font-size: ${({ theme }) => theme.fontSizes.f14};
	line-height: ${({ theme }) => theme.lineHeight.line9};
	font-weight: ${({ theme }) => theme.font.regular};
	color: ${({ theme }) => theme.colors.stringWhite};
	width: 100%;
	
	tr {
		border: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-top: none;
		border-collapse: collapse;
		display: table;
		table-layout: fixed;
		width: 100%;
	}
	
	tbody {
		margin-top:-2px;

		&:first-child {
		 tr {
		 	&:first-child {
				border-top:3px solid ${({ theme }) => theme.colors.stringWhite};
		 	}
		 }
		}
	}
	
	td {
		padding: 10px;
	}
	
	span {
		font-weight: ${({ theme }) => theme.font.bold};
		
		@media (min-width: 1000px){
			font-weight: ${({ theme }) => theme.font.regular};
			padding: 10px 10px 10px 45px;
		}
	}
	
	.isMobile {
			font-weight: ${({ theme }) => theme.font.regular};
		@media (min-width: 1000px){
			display: none;
		}
	}
	.isDesktop {
		display: none;
		min-height: 40px;
		
		@media (min-width: 1000px){
			display: block;
			border-left: 3px solid ${({ theme }) => theme.colors.stringWhite};
			padding: 10px 10px 10px 45px;
		}
		
		@media (min-width: 1080px){
			display: block;
			border-left: 3px solid ${({ theme }) => theme.colors.stringWhite};
			padding: 10px 10px 10px 115px;
		}
	}
`

const Header = styled(Headers)`
	text-transform: uppercase;
	letter-spacing: 3px;
	width: 130px;
	margin-bottom: 20px;
	
	@media (min-width: 1000px){
			position: absolute;
			margin-top: 25px;
	}
`

const ExpandedBtn = styled(LinkButton)`
	&.expand {
		margin-top: 10px;
	}
`

const InvisibleLink = styled.a`
	text-decoration: none;
`

const ButtonBlueBorderWhiteExtended = styled(ButtonBlueBorderWhite)`
	margin: 40px auto 30px;
	padding: 20px 50px 20px 40px;
	
	span {
		position: relative;
		
		&:after {
			content: '';
			position: absolute;
			right: -25px;
			top: 50%;
			transform: translateY(-50%);
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-left: 7px solid ${({ theme }) => theme.colors.stringWhite};
		}	
	}
	
	&:hover { 
		span {
			&:after {
				border-left: 7px solid ${({ theme }) => theme.colors.stringBlue};
			}	
		}
	}
`

class ProductSpecification extends React.Component {

	state = {
		activeProductIndex: 0,
		tableRowExpanded: false,
	}

	handleChange = (selectedOption, selectProductArray) => {
		this.setState({ activeProductIndex: selectProductArray.indexOf(selectedOption.value) })
		this.setState({ tableRowExpanded: false })
	}

	expandTable = () => {
		this.setState({ tableRowExpanded: !this.state.tableRowExpanded })
	}

	render() {
		const specData = (this.props.data && this.props.data.produktSpecyfikacja) || []
		const header = (specData[0] && specData[0].nazwa) || ""
		const extendTextBtn = (specData[0] && specData[0].rozwinWszystkie) || ""
		const collapseTextBtn = (specData[0] && specData[0].zwin) || ""
		const pdfTextBtn = (specData[0] && specData[0].pobierzPdf) || ""
		const tableData = specData.slice(1) || []
		const selectProductArray = []
		const tableCollapsedArray = []
		const tableExpandedArray = []
		const pdfFileUrl = []
		if (tableData.length > 0) {
			tableData.map((item) => {
					const productName = item.nazwaProduktu || ""
					selectProductArray.push(productName)
					tableCollapsedArray.push(item.tabelkaPrzedRozwinieciem || [])
					pdfFileUrl.push(item.plik && item.plik[0] && item.plik[0].url || [])
					item.tabelkaPoRozwinieciu !== null ? tableExpandedArray.push(item.tabelkaPoRozwinieciu) : ""
				}
			)
		}

		const isDrpodownDisabled = selectProductArray.length === 1

		return (
			<ProductSpecWrapper>
				<Header className="font-size-h11 color-white font-weight-regular line-height-8">{header}</Header>
				{selectProductArray.length > 0 &&
				<Dropdown controlClassName='customControl' menuClassName='customMenu'
									arrowClassName={selectProductArray.length === 1 ? "customArrow hidden" : "customArrow"}
									options={selectProductArray} value={selectProductArray[this.state.activeProductIndex]}
									onChange={(selectedOption) => this.handleChange(selectedOption, selectProductArray)}
									disabled={isDrpodownDisabled}/>
				}
				<TableStyled>
					<tbody>
					{tableCollapsedArray.length > 0 && tableCollapsedArray[this.state.activeProductIndex].map((row, key) =>
						<tr key={key}>
							<td><span>{row.nazwa}</span><br/> <span className="isMobile"> {row.opis} </span></td>
							<td className="isDesktop">{row.opis}</td>
						</tr>,
					)
					}
					</tbody>

					<RowExtended pose={this.state.tableRowExpanded ? "visible" : "hidden"}>
						{tableExpandedArray.length > 0 && tableExpandedArray[this.state.activeProductIndex].map((row, key) =>
							<tr key={key}>
								<td><span>{row.nazwa}</span><br/> <span className="isMobile"> {row.opis}</span></td>
								<td className="isDesktop">{row.opis}</td>
							</tr>)
						}
					</RowExtended>
				</TableStyled>


				{tableExpandedArray.length > 0 && !this.state.tableRowExpanded &&
				<ExpandedBtn className="expand" onClick={this.expandTable}>{extendTextBtn}</ExpandedBtn>}
				{tableExpandedArray.length > 0 && this.state.tableRowExpanded &&
				<ExpandedBtn onClick={this.expandTable}>{collapseTextBtn}</ExpandedBtn>}


				{pdfFileUrl[this.state.activeProductIndex].length > 0 &&
				<InvisibleLink href={pdfFileUrl[this.state.activeProductIndex]} target="_blank">
					<ButtonBlueBorderWhiteExtended><span>{pdfTextBtn}</span></ButtonBlueBorderWhiteExtended>
				</InvisibleLink>}
			</ProductSpecWrapper>
		)
	}
}

export default ProductSpecification
