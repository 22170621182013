import React from "react"
import styled from "styled-components"
import getSingleImage from "../../utils/getSingleImage"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"


const AboutUsManagementGeneralWrapper = styled.div`
  width: 100%;

  @media (min-width: 1000px){
  	min-height: 1023px;
  }
`

const AboutUsSemiWrapper = styled.div`
  display: flex;
  min-height: 1178px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media (max-width: 450px){
    min-height: 100%;
  }
`

const ManagementHeader = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.stringBlue};
  font-size: ${({ theme }) => theme.headers.h4};
	font-weight: ${({ theme }) => theme.font.bold};
	padding: 0 0 0 336px;
	margin: 78px 0 56px 0;

	@media (max-width: 1400px){
		padding: 0 0 0 138px
	};
	@media (max-width: 999px){
		padding: 0 35px
  };
 
  @media (max-width: 375px){
    margin: 50px 0 56px 0
	};
`

const UpperRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  
  @media(min-width: 1000px){
  	flex-flow: row;
  	justify-content: center;
  }
`
const BottomRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  bottom: 0;
 	padding: 0;
  flex-flow: column;
  position: static;

  :before{
    content: "";
    height: 230px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.stringBlack};
  };
  
  @media (min-width: 999px){
  	position: absolute;
  	flex-flow: row;
  	justify-content: center;
  	 padding: 0 0 0 95px;
  	 top: 500px;
  };
`

const ManagementCitationBoxBlack = styled.div`
	width: 100%;
 	position: static;
  background-color: ${({ theme }) => theme.colors.stringBlack};
  display: flex;
  flex-flow: column;
  padding: 76px 35px;
  color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  font-weight: ${({ theme }) => theme.font.regular};
  line-height: 2;
    
  :before{
    content: "";
    height: 649px;
    width: 17px;
    position: absolute;
    top: 0;
    right: -17px;
    background-color: ${({ theme }) => theme.colors.stringBlack};
  };
	@media (min-width: 1000px){
		padding: 76px 66px;
		min-height: 649px;
  	width: 632px;
  	position: relative;
	};

`
const ManagementCitationBoxBlue = styled.div`
  width: 100%;
  position: static;
  background-color: ${({ theme }) => theme.colors.stringBlue};
	z-index: 3;
	display: flex;
	flex-flow: column;
  padding: 35px;
  color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  font-weight: ${({ theme }) => theme.font.regular};
	line-height: 2;

	@media (min-width: 1000px){
	 	padding: 45px;
		min-height: 634px;
  	width: 634px;
  	position: relative;
	};
`

const ManagementImageWrapper = styled.div`
  position: relative;
  z-index: 3;
  margin: 0;
  padding: 0;
  width: 100%;
    
  @media (min-width: 1000px){
  	margin: 71px 0 0 0;
  	height: 393px;
  	width: 393px;
  };
  
  &.nearBlue{
    margin: 71px 35px 0 0;
    border: none;
    @media (max-width: 999px){
      margin: 0;
      padding: 0;
      border: none
    };
  };
`

const ManagementImage = styled.img`
	width: 100%;
  padding: 0;
  margin: 0 0 -4px 0;
  
  @media(min-width: 1000px) {
  	width: auto;
  }
`

const Citation = styled.div`
`

const CitationAuthor = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	font-style: normal;
`
const CitationAuthorPosition = styled.div`
  font-style: normal;
  margin: 0 0 37px 0    
`

class AboutUsManagement extends React.Component {

	render() {

		const ManagementData = this.props.data.zarzad
		const MamagementArray = []

		ManagementData.forEach(item => {
			MamagementArray.push({
				"name": (item && item.imieINazwisko || ""),
				"header": (item && item.naglowek || ""),
				"position": (item && item.stanowisko || ""),
				"imageUrl": (item && item.zdjecie || ""),
				"managementDescription": (item.opis),
				"managementDescription2": (item.opis2),
				"name2": (item && item.imieINazwisko2 || ""),
				"position2": (item && item.stanowisko2 || ""),
				"imageUrl2": (item && item.zdjecie2 || ""),
			})
		})

		return (
			<AboutUsManagementGeneralWrapper>
				{MamagementArray.map((managementElements, key) =>
					<ManagementHeader key={key}>{managementElements.header}</ManagementHeader>,
				)}
				{MamagementArray.map((managementElements, key) =>
					<AboutUsSemiWrapper key={key}>
						<UpperRow>
							<ManagementCitationBoxBlack>
								<CitationAuthor>{managementElements.name}</CitationAuthor>
								<CitationAuthorPosition className="black">{managementElements.position}</CitationAuthorPosition>
								<Citation dangerouslySetInnerHTML={getHtmlFromRedactorField(managementElements.managementDescription)}/>
							</ManagementCitationBoxBlack>
							<ManagementImageWrapper>
								<ManagementImage src={getSingleImage(managementElements.imageUrl)}/>
							</ManagementImageWrapper>
						</UpperRow>
						<BottomRow>
							<ManagementImageWrapper className="nearBlue">
								<ManagementImage src={getSingleImage(managementElements.imageUrl2)}/>
							</ManagementImageWrapper>
							<ManagementCitationBoxBlue>
								<CitationAuthor>{managementElements.name2}</CitationAuthor>
								<CitationAuthorPosition className="blue">{managementElements.position2}</CitationAuthorPosition>
								<Citation
									dangerouslySetInnerHTML={getHtmlFromRedactorField(managementElements.managementDescription2)}/>
							</ManagementCitationBoxBlue>
						</BottomRow>
					</AboutUsSemiWrapper>,
				)}
			</AboutUsManagementGeneralWrapper>
		)
	}
}

export default AboutUsManagement
