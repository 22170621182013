import React from "react"
import styled from "styled-components"
import ContentWrapperColumn from "../common/pageContainer/ContentContainer"
import ColumnOne from "../footer/columnOne"
import ColumnTwo from "../footer/columnTwo"
import ColumnThree from "../footer/columnThree"
import ColumnFour from "../footer/columnFour"
import pleoSVG from "../../icons/PLEO_digital.svg"

const ContentWhitekWrapper = styled(ContentWrapperColumn)`
	background-color: ${({ theme }) => theme.colors.stringBlack};
	margin: 0;
	height: auto;
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
`

const FooterWrapper = styled.div`
	position: relative;
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.stringBlack};
	width: 100%;
	max-width: 100vw;
	min-height: 440px;
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
	padding: 92px 140px 0 140px;

	@media (max-width: 1400px) {
		padding: 92px 35px 0 35px;
	}
	
	@media (max-width: 375px) {
		padding: 66px 35px 76px 35px;
	}

	@media (max-width: 680px) {
		width: 100%;
		justify-content: space-between;
	}
`

const AuthorInfo = styled.div`
	position: absolute;
	display: flex;
	flex-wrap: nowrap;
	bottom: 35px;
	right: 35px;
	font-size:   ${({ theme }) => theme.fontSizes.f17};
	color:  ${({ theme }) => theme.colors.stringWhite};
		
	img {
		margin-left: 5px;
	}
`

const AuthorInfoText = styled.span`
	width: 100%;
	white-space: nowrap;
`

class Footer extends React.Component {
	render() {
		const { footerData, socialMediaData, activeSite } = this.props
		const columnOneData = footerData.stopka1Kolumna || []
		const columnTwoData = footerData.stopka2Kolumna || []
		const columnThreeData = footerData.stopka3Kolumna || []
		const columnFourData = socialMediaData
		const socialMediaHeaderData = footerData.stopkaZnajdzNas
		return (
			<FooterWrapper>
				<ContentWhitekWrapper>
					<ColumnOne columnOneData={columnOneData}/>
					<ColumnTwo columnTwoData={columnTwoData} activeSite={activeSite}/>
					<ColumnThree columnThreeData={columnThreeData} activeSite={activeSite}/>
				</ContentWhitekWrapper>
				<ColumnFour socialMediaHeaderData={socialMediaHeaderData} columnFourData={columnFourData}/>
				<AuthorInfo>
					<AuthorInfoText>
						designed & developed by
					</AuthorInfoText>
					<a href="https://pleodigital.com/" target="_blank" rel="nofollow">
						<img src={pleoSVG} alt="PLEO digital"/>
					</a>
				</AuthorInfo>
			</FooterWrapper>
		)
	}
}


export default Footer
