import React from "react"
import styled from "styled-components"
import InternalLink from "../common/InternalLink"
import getSlug from "../../utils/getSlug"

const FooterHeader = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.headers.h7};
	color: ${({ theme }) => theme.colors.stringWhite};

	@media (max-width: 800px){
		padding: 0 0 0 0
	};
`
const FooterBoxesPolicy = styled.div`
	padding: 0 0 45px 0;
	line-height: 77px;
	height: auto;
	margin: 0 30px 0 0;
	
	@media (min-width: 375px){
		margin: 0 0 0 30px;
	};

	@media (max-width: 800px){
		width: 140px;
	};
`

const ULLIst = styled.ul`
	font-weight: ${({ theme }) => theme.font.regular};
	color: ${({ theme }) => theme.colors.stringWhite};
	list-style-typne: none;
	padding: 0;
	margin: 0;
	line-height: 2;

	@media (max-width: 800px){
		padding: 0 0 0 0
	};
`

const ListItemPolicy = styled.li`
	margin: 0;
`

class ColumnThree extends React.Component {
	render() {

		const { columnThreeData, activeSite } = this.props
		const pages = columnThreeData[0].podstrony
		const thirdColumn = []
		const thirdKolumnContent = []
		columnThreeData.forEach(item => {
			thirdColumn.push({
				"naglowek": item.naglowek || "",

			})
		})
		pages.forEach(item => {
			thirdKolumnContent.push({
				"slugTitle": item.title || "",
				"slug": getSlug(item.slug, activeSite) || "",
			})
		})

		return (
			<FooterBoxesPolicy>
				{thirdColumn.map((footer, key) =>
					<FooterHeader key={key}>{footer.naglowek}</FooterHeader>,
				)
				}
				{thirdKolumnContent.map((footerContent, key) =>
					<ULLIst key={key}>
						<InternalLink to={footerContent.slug}>
							<ListItemPolicy>{footerContent.slugTitle}</ListItemPolicy>
						</InternalLink>
					</ULLIst>,
				)
				}
			</FooterBoxesPolicy>
		)
	}
}

export default ColumnThree
