import React from "react"
import styled from "styled-components"

const CareerOfferContent = styled.div`
	background-color: ${({ theme }) => theme.colors.stringWhite};
	margin: 0;
	display: flex; 
	flex-flow: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0;
`

const OfferHeaderWrapper = styled.div`
	width: 100%;
	padding: 0 138px;

	@media (min-width: 1327px){
		padding: 0 0 0 336px;
	};
	@media (max-width: 800px){
		padding: 0 36px;
	};
`

const CareerOfferHeader = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.headers.h2};
	width: 100%;
	color: ${({ theme }) => theme.colors.stringBlue};
	padding: 86px 0;

	@media (max-width: 800px){
		font-size: ${({ theme }) => theme.headers.h6};
		line-height: 50px;
		text-align: left;
	};
	@media (max-width: 800px){
		width: 65%;
		padding: 64px 0;
	};
	@media (max-width: 400px){
		width: 65%;
		font-size: ${({ theme }) => theme.headers.h6};
	};
	@media (max-width: 375px){
		width: 70%;
	};
`

class CareerHeader extends React.Component {

	render() {
		const CareerOfferHeaderData = this.props.data.naglowekIOpis
		const CareerHeader = CareerOfferHeaderData[0].naglowek

		return (
			<CareerOfferContent>
				<OfferHeaderWrapper>
					<CareerOfferHeader>{CareerHeader}</CareerOfferHeader>
				</OfferHeaderWrapper>
			</CareerOfferContent>
		)
	}
}

export default CareerHeader
