import React from "react"
import styled from "styled-components"
import getSingleImage from "../../utils/getSingleImage"
import ButtonBlueBorderWhite from "../common/buttons/Button"
import getSlug from "../../utils/getSlug"
import StyledLink from "../common/buttons/ButtonLinkStyle"


const TrustGeneralWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.stringBlue};
  color: ${({ theme }) => theme.colors.stringWhite};
  min-height: 595px;
  padding: 86px 165px 108px 137px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  
  
  @media (max-width: 1400px){
    padding: 86px 72px 108px 36px;

  }
  @media (max-width: 999px){
    padding: 43px 36px 43px 36px;

  }

`
const HeaderTrust = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 0 130px 0;
  
  @media (max-width: 375px){
    justify-content: flex-end;
    flex-flow: column;
    margin: 0 0 100px 0
  }
  `
const TrustNumber = styled.div`
  width: 10%;
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.headers.h10h};
  margin: 0;
  padding: 10px 0 0 0;
  
  @media (max-width: 375px){
    text-align: right;
    margin: 0 0 34px 0;
    width: 100%;
    padding: 0;
  }
  
  `
  const TrustTitle = styled.div`
  text-align: right;
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.headers.h4};
  width: 100%;
  
  @media (max-width: 375px){
    font-size: ${({ theme }) => theme.headers.h6};
  }
  `
const BoxForPiktogramsTrust = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;

  @media (max-width: 1400px){
    width: 90%;
    padding: 0 0 0 50px;

  };
  @media (max-width: 1090px){
    width: 100%;
    padding: 0

  };

  @media (max-width: 999px){
    margin: 40px 0 0 0;
    padding: 0;
    width: 50%;

  }

`
const PiktogramImageTrust = styled.img`
  height: 68px;
  margin: 0 0 34px 0;
  width: 100%;
  text-align: center;
  position: relative;
  // border: 1px solid pink;

  :before{
		content: "";
		width: 8px;
		height: 8px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: ${({ theme }) => theme.colors.stringWhite}

  };

  @media (max-width: 999px){
    height: 100px;
  }
  
  `
const PiktogramDescriptionTrust = styled.div`
  text-align: center;
  margin: 0;
  line-height: 2;
  font-size: ${({ theme }) => theme.paragraphs.p2};
  font-weight: ${({ theme }) => theme.font.light};
  width: 145px;
  box-sizing: border-box;

  @media (max-width: 999px){
    width: 40%
  }
  @media (max-width: 590px){
    width: 100%
  }
  @media (max-width: 375px){
    width: 70%
  }
`
const TrustWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  width: 70%;

  @media (max-width: 1400px){
    width: 100%;
  };

  @media (max-width: 999px){
    flex-flow: row wrap;
    margin: 0;
    width: 100%;
  }
  @media (max-width: 375px){
    justify-content: flex-start;
  }

`

const TrustButton = styled(ButtonBlueBorderWhite)`
  width: 265px;
  background-color: ${({ theme }) => theme.colors.stringBlue};
  margin: 100px 0 0 0;

  :hover :before{
		margin: 0 85% 0 0;
	};

`


const Grid = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center
`

const GridElement = styled.div`
  width: 274px;
  height: 100%;
  border-right: 0.5px solid rgba(225,225,225, 0.5);
  padding: 0;
  margin: 0;
  
  &.first{
    border-left: 0.5px solid rgba(225,225,225, 0.5);

  }

`
const GridLineTop = styled.div`
  width: 100%;
  height: calc(100% / 3);
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(225,225,225, 0.5);
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center

`
const GridLinemiddle = styled.div`
  width: 100%;
  height: calc(100% / 3);
  top: 0;
  left: 0;
  // border-top: 1px solid rgba(225,225,225, 0.5);
  border-bottom: 1px solid rgba(225,225,225, 0.5);
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center

`
const GridLineBottom = styled.div`
  width: 100%;
  height: calc(100% / 3);
  top: 0;
  left: 0;
  // border-top: 1px solid rgba(225,225,225, 0.5);
  // border-bottom: 1px solid rgba(225,225,225, 0.5);
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center

`

class ProductTrustUs extends React.Component {
  render() {
  	const activeSite = this.props.activeSite
    const trustData = this.props.data.produktZaufalyNam;
    const newTrustData = trustData.slice(1)
    const trusttitle = this.props.data.produktZaufalyNam[0].naglowek;
    const trustnumber = this.props.data.produktZaufalyNam[0].numer;
    const trustArray = [];

    newTrustData.forEach(item => {
      trustArray.push({
        "description": item.opis,
        "pictogram": item.piktogram,

        })
    })

    const buttonDescription = this.props.data.produktZaufalyNam[0].przyciskNapis
    const buttonslug = this.props.data.produktZaufalyNam[0].przyciskLink[0].slug
    const trustButtonSlug = getSlug(buttonslug, activeSite)

    return (
      <TrustGeneralWrapper>
        <Grid>  
          <GridLineTop>
            <GridElement className="first"/>
            <GridElement />
          </GridLineTop>
          <GridLinemiddle>
            <GridElement className="first"/>
            <GridElement />
          </GridLinemiddle>
          <GridLineBottom>
            <GridElement className="first"/>
            <GridElement />
          </GridLineBottom>


        </Grid>
        <HeaderTrust>
          <TrustNumber>{trustnumber}</TrustNumber>
          <TrustTitle>{trusttitle}</TrustTitle>
        </HeaderTrust>
        <TrustWrapper>
          {trustArray.map((trust, key) =>
            <BoxForPiktogramsTrust key={key}>
              <PiktogramImageTrust className="hover-image" src={getSingleImage(trust.pictogram)} alt={trust.pictogram[0].altText} />
              <PiktogramDescriptionTrust>{trust.description}</PiktogramDescriptionTrust>
            </BoxForPiktogramsTrust>
          )
          }
        </ TrustWrapper>
        <TrustButton as={StyledLink} to={`/${trustButtonSlug}`} >{buttonDescription}</TrustButton>
      </TrustGeneralWrapper>

    )
  }
}
export default ProductTrustUs
