import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import StyledLink from "../common/buttons/ButtonLinkStyle"
import getSingleImage from "../../utils/getSingleImage"
import getSlug from "../../utils/getSlug"
import { connect } from "react-redux"
import { changeLogoColor } from "../../state/app"
import { ButtonWhiteBlackBackground } from "../common/buttons/Button"

const CareerAboutUs = styled.div`
	width: 100%;
	min-height: 1068px;
	background-color: black;
	color: ${({ theme }) => theme.colors.stringWhite};
	padding: 216px 318px 256px 318px;
	position: relative;
	
	@media (max-width: 1400px){
		padding: 216px 138px 256px 138px	
	};
	@media (max-width: 999px){
		padding: 216px 36px 256px 36px	
	};
	@media (max-width: 600px){
		padding: 83px 36px 143px 36px		
	};
`

const CareerFirstDescription = styled.div`
  width: 70%;
	color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.fontSizes.f10};
	font-weight: ${({ theme }) => theme.font.regular};
	line-height: 1.8;
	margin: 67px 0 109px 0;
	position: relative;
	z-index: 3;
	opacity: 1;

	p{
		padding: 0;
		width: 100%;
	};
	
	@media (max-width: 600px){
		width: 100%;
		font-size: ${({ theme }) => theme.fontSizes.f23};
		margin: 60px 0 50px 0
	};
`
const CareerSecondDescription = styled.div`
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.fontSizes.f14};
	font-weight: ${({ theme }) => theme.font.regular};
	line-height: 1.8;
	margin: 0 0 146px 0;
	width: 65%;
	position: relative;
	z-index: 3;
	opacity: 1;

	p{
		padding: 0;
		width: 100%;
	};
	
	@media (max-width: 600px){
		width: 100%;
		margin: 0 0 53px 0
	};
`
const CareerHeader = styled.div`
	width: 100%;
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.headers.h4};
	font-weight: ${({ theme }) => theme.font.bold};
	position: relative;
	z-index: 3;
	opacity: 1;

	@media (max-width: 600px){
		font-size: ${({ theme }) => theme.headers.h6};
		width: 45%
	};
	@media (max-width: 375px){
		width: 85%
	};

`
const ButtonWrapper = styled.a`
	height: 60px;
	width: 286px;
	border-radius: 7px;
	color: ${({ theme }) => theme.colors.stringBlue};
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.paragraphs.p2};
	text-decoration: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	padding: 0;
  position: absolute;
  z-index: 3;
  opacity: 1;


	:hover{
		background-color: ${({ theme }) => theme.colors.stringWhite};
	};
	:hover .left{
		Width: 16px;
	};
	:hover .right{
		Width: 256px;
	};
	:hover .content{
		color: ${({ theme }) => theme.colors.stringBlack};
	};
`

const LeftPart = styled.div`
	border-top: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-left: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-bottom: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	width: 256px;
	height: 105%;
	margin: 0;
	padding: 0;
	position: absolute;
	top:0;
	left: 0;
	transition: margin 0.5s linear;
	z-index: 3;
	opacity: 1; 
`

const RightPart = styled.div`
	border-top: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-right: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-bottom: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	width: 16px;
	height: 105%;
	margin: 0;
	padding: 0;
	position: absolute;
	top:0;
	right: 0;
	transition: margin 0.5s linear;
	z-index: 3;
	opacity: 1;


	:before{
		content: "";
		width: 14px;
		height: 3px;
		background-color: ${({ theme }) => theme.colors.stringWhite};
		position: absolute;
		top: -3px;
		left: -14px;
	}
`

const ButtonContent = styled.div`
	color: ${({ theme }) => theme.colors.stringWhite};
	position: absolute;
	top: 25px;
	left: 47px;
	width: auto;
	height: auto;
	background-color: transparent;
	z-index: 3;
	opacity: 1;
`

const BackgroundImage = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0
`

const Image = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${props => props.back});
	opacity: .4;
	background-position: center;
	background-size: cover;
	border: 0;
	padding: 0;
	margin: 0;

	@media (max-width: 595px){
		background-position-y: 365px;
	}
`

const ButtonWhiteBlackBackgroundExtended = styled(ButtonWhiteBlackBackground)`
	min-width: auto;
	padding: 20px 45px;
	white-space: nowrap;
`

class AboutUsCareer extends React.Component {

	render() {
		const { dispatch, activeSite } = this.props
		dispatch(changeLogoColor(false))
		const AboutUsCareerBannerData = this.props.data.karieraWString
		const AboutUsCareerBannerArray = []

		AboutUsCareerBannerData.forEach(item => {
			AboutUsCareerBannerArray.push({
				"header": (item && item.naglowek || ""),
				"imageUrl": (item && item.zdjecie || ""),
				"description": (item && item.opis || ""),
				"description2": (item && item.opis2 || ""),
				"buttonDescription": (item && item.przyciskOpis || ""),
				"buttonLink": (item && item.przyciskLink[0] && item.przyciskLink[0].slug || ""),
			})
		})

		return (
			<CareerAboutUs>
				<BackgroundImage>
					{AboutUsCareerBannerArray.map((aboutUsCareerBanner, key) =>
						<Image key={key} back={getSingleImage(aboutUsCareerBanner.imageUrl)}/>,
					)}
				</BackgroundImage>
				{AboutUsCareerBannerArray.map((aboutUsCareerBanner, key) =>
					<div key={key}>
						<CareerHeader>{aboutUsCareerBanner.header}</CareerHeader>
						<CareerFirstDescription
							dangerouslySetInnerHTML={getHtmlFromRedactorField(aboutUsCareerBanner.description)}/>
						<CareerSecondDescription
							dangerouslySetInnerHTML={getHtmlFromRedactorField(aboutUsCareerBanner.description2)}/>
						<StyledLink to={`/${getSlug(aboutUsCareerBanner.buttonLink, activeSite)}`}>
							<ButtonWhiteBlackBackgroundExtended className="large">
								<span>{aboutUsCareerBanner.buttonDescription}</span>
							</ButtonWhiteBlackBackgroundExtended>
						</StyledLink>
					</div>,
				)}
			</CareerAboutUs>
		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(AboutUsCareer)
