import React from "react"
import HomeSlider from "./homeSlider/HomeSlider"
import About from "./knowUs/About"
import HeaderAndDescription from "./headerAndDescription/headerAndDescription"
import News from "./news/News"
import ProductPresentation from "./productPresentation/ProductPresentation"
import Product3dPresentation from "./product3dPresentation/Product3dPresentation"
import ProductPictograms from "./productPictograms/ProductPictograms"
import ProductTrustUs from "./trustUs/TrustUs"
import ProductBanner from "./productBanner/ProductBanner"
import ProductSpecification from "./productSpecification/productSpecification"
import SeeAlso from "./seeAlso/seeAlso"
import AboutUsBanner from "./aboutUsBanner/aboutUsBanner"
import AboutUsManagement from "./aboutUsManagement/aboutUsManagement"
import AboutUsCareer from "./aboutCareerBaner/aboutCareerBanner"
import ProductInterface from "./productInterface/productInterface"
import CareerHeaderAndDescription from "./careerHeaderAndDescription/careerHeaderAndDescription"
import CareerHeader from "./careerHeader/careerHeader"
import CareerBanner from "./careerBanner/careerBanner"
import SingleNews from "./SingleNews/SingleNews"
import PageContent from "./pageContent/PageContent"
import ListOfNewsBanner from "./listOfNewsBanner/ListOfNewsBanner"
import ListOfNews from "./listOfNews/ListOfNews"
import WontToBuy from "./wontToBuy/wontToBuy"
import PrivacyPolicyBanner from "./privacyPolicyBanner/privacyPolicyBanner"
import ProductBuy from "./productBuy/ProductBuy"
import ProductInfographic from "./productInfographic/ProductInfographic"
import String404 from "./string404/String404"
import LazyLoad from 'react-lazyload';

import loadable from '@loadable/component'
const Contact  = loadable(() => import("./contact/Contact"))
const ProductGallery  = loadable(() => import("./productGallery/ProductGallery"))
const CareerOffers  = loadable(() => import("./careerOffers/careerOffers"))
const Map = loadable(() => import("./wontKnowsMore/MapSection"))
const ProductPackage = loadable(() => import("./productPackage/ProductPackage"))

const ExtendedContent = (props) => {
	const { data, modalData, translationsData, pageContext, newsAll, activeSite } = props || []

	if (Array.isArray(data)) {
		return (
			data.map((block, i) => {
				switch (block.__typename) {
					case "Craft_moduly_slider_Entry":
						return <HomeSlider key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_naglowekOpisIPrzycisk_Entry":
						return <About key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_naglowekIopis_Entry":
						if (block.slug === "moduł-nagłówek-i-opis") {
							return <HeaderAndDescription key={i} data={block}/>
						} else if (block.slug === "moduł-nagłówek-kariera") {
							return <CareerHeader key={i} data={block}/>

						} else {
							return <CareerHeaderAndDescription key={i} data={block}/>
						}
					case "Craft_moduly_wizytowkaAktualnosci_Entry":
						return<LazyLoad height={580} offset={300}><News key={i} data={block} translationsData={translationsData} activeSite={activeSite}/></LazyLoad>
					case "Craft_moduly_chceszWiedziecWiecej_Entry":
						return <LazyLoad height={690}><Map key={i} data={block} activeSite={activeSite}/></LazyLoad>
					case "Craft_moduly_wizytowkaProdukt_Entry":
						return <LazyLoad height={640} offset={300}><ProductPresentation key={i} data={block} activeSite={activeSite}/></LazyLoad>
					case "Craft_moduly_produktPrezentacja3d_Entry":
						return <Product3dPresentation key={i} data={block}/>
					case "Craft_moduly_produktPiktogramy_Entry":
						return <ProductPictograms key={i} data={block}/>
					case "Craft_moduly_produktZaufalyNam_Entry":
						return <ProductTrustUs key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_produktSpecyfikacja_Entry":
						return <ProductSpecification key={i} data={block}/>
					case "Craft_moduly_produktBaner_Entry":
						return <ProductBanner key={i} data={block}/>
					case "Craft_moduly_zobaczRowniez_Entry":
						return <SeeAlso key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_produktJestesZainteresowanyKupnem_Entry":
						return <WontToBuy key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_produktInterface_Entry":
						return <ProductInterface key={i} data={block}/>
					case "Craft_moduly_galeria_Entry":
						return <ProductGallery key={i} data={block}/>
					case "Craft_moduly_produktOpakowanie_Entry":
						return <ProductPackage key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_banerDlaONas_Entry":
						return <AboutUsBanner key={i} data={block}/>
					case "Craft_moduly_kontaktModulKontaktowy_Entry":
						return <Contact key={i} data={block} modalConfirmData={modalData}  pageContext={pageContext} />
					case "Craft_moduly_zarzad_Entry":
						return <AboutUsManagement key={i} data={block}/>
					case "Craft_moduly_karieraWString_Entry":
						return <AboutUsCareer key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_ofertyPracy_Entry":
						return <CareerOffers key={i} data={block} modalData={modalData} modalConfirmData={modalData}
																 translationsData={translationsData}/>
					case "Craft_moduly_banerZTekstemIZdjeciem_Entry":
						return <CareerBanner key={i} data={block}/>
					case "Craft_moduly_trescPodstrony_Entry":
						return <PageContent key={i} data={block.trescPodstrony}/>
					case "Craft_moduly_banerZNachodzacaAktualnoscia_Entry":
						return <ListOfNewsBanner key={i} data={block} pageContext={pageContext} translationsData={translationsData} activeSite={activeSite}/>
					case "Craft_moduly_pokazAktualnosci_Entry":
						return <ListOfNews key={i} data={block} pageContext={pageContext} translationsData={translationsData}
															 newsAll={newsAll} activeSite={activeSite}/>
					case "Craft_moduly_banerTekstowy_Entry":
						return <PrivacyPolicyBanner key={i} data={block}/>
					case "Craft_moduly_produktKupno_Entry":
						return <ProductBuy key={i} data={block} activeSite={activeSite}/>
					case "Craft_moduly_produktInfografika_Entry":
						return <ProductInfographic key={i} data={block}/>
					case "Craft_moduly_string404_Entry":
						return <String404 key={i} data={block} activeSite={activeSite}/>
					default:
						return <h1 key={i}>Brak obsługi dla {block.__typename}</h1>
				}
			})
		)
	} else {

		switch (data.__typename) {
			case "Craft_aktualnosci_aktualnosci_Entry":
				return <SingleNews data={data} translationsData={translationsData} pageContext={pageContext} activeSite={activeSite}/>
			default:
				return <h1 key={i} >Brak obsługi dla {data.__typename}</h1>
		}
	}
}

export default ExtendedContent
