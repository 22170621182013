import React from "react"
import styled from "styled-components"


const WhiteQube = styled.div`
    height: 7px;
    background-color: ${({theme}) => theme.colors.stringWhite};
    width: 7px;
    position: absolute;
    top: 13px;
    left: 0;
`

export const BlackQube = styled(WhiteQube)`
    background-color: ${({theme}) => theme.colors.stringBlack};

`
export const BluekQube = styled(WhiteQube)`
    background-color: ${({theme}) => theme.colors.stringBlue};

`

export default WhiteQube
