import React from "react"
import styled from "styled-components"

const StyledCheckboxLabel = styled.label`
  margin: 20px 0;
  
  p {
    margin: 0;
    padding-left: 42px;
    font-size: ${({ theme }) => theme.fontSizes.f15};
    line-height: ${({ theme }) => theme.lineHeight.line8};
    color: ${({ theme }) => theme.colors.stringWhite};
    
    @media (min-width: 1000px) {
			font-size:  ${({ theme }) => theme.fontSizes.f16};
			line-height: ${({ theme }) => theme.lineHeight.line15};
			padding-right: 10px;
		}
    a {
      color: ${({ theme }) => theme.colors.stringWhite};
      text-decoration: none;
    }
  }
`

const StyledCheckboxInput = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	
	.checkbox-square {
		position: absolute;
		opacity: 0;
		z-index: 1;
		width: 40px;
		height: 40px;
		margin-left: -8px;
		top: 8px;

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}

	& + label:before {
		content: '';
		margin-right: 10px;
		position: absolute;
		vertical-align: text-top;
		width: 22px;
		height: 22px;
		border: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-radius: 5px;
		background: transparent;
		top: 0;
	}

	&:checked + label:before {
		background: transparent;
	}

	& + label:after  {
		content: '';
			position: absolute;
			left: 8px;
			top: 8px;
			width: 6px;
			height: 6px;
			background-color: white;
			opacity: 0;
			transition: opacity 0.4s ease;
		}
			
		&:checked + label:after {
			opacity: 1;
    }
}
`

const CheckboxInfo = (props) => {
	const { children, checkboxid } = props
	const newProps = { ...props, children: null }

	return (
			<StyledCheckboxInput {...newProps}>
				<input className="checkbox-square" id={checkboxid ? checkboxid : 'checkbox'} {...newProps}/>
				<StyledCheckboxLabel htmlFor={checkboxid ? checkboxid : 'checkbox'}>
					<div>{children}</div>
				</StyledCheckboxLabel>
			</StyledCheckboxInput>
	)
}

export default CheckboxInfo
