import React from "react"
import styled from "styled-components"
import { ProductDescription, ProductHeader, ProductNumber } from "../common/headers/Headers"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import RotatableImage from "../rotableImage/RotableImage"

const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.stringBlue};
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

const GridBackground = styled.div`
	background-size: 60px 60px;
	width: 100%;
	background-image: linear-gradient(to right, rgba(150, 159, 237, .5) 1px, transparent 1px), linear-gradient(to bottom, rgba(150, 159, 237, .5) 1px, transparent 1px);
	padding: 60px 35px;
	@media (min-width: 1000px) {
		background-size: 240px 240px;
	}
	@media (min-width: 1400px) {
		padding: 60px 140px;
	}
`

const TextContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: end;
	@media (min-width: 1000px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
  }
`

const ModelWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	user-select: none;
	
	@media (min-width: 1000px) {
		margin: 100px auto;
  }
`

class Product3dPresentation extends React.Component {
	render() {

		const productData = (this.props.data && this.props.data.produktPrezentacja3d && this.props.data.produktPrezentacja3d[0]) || []
		const productNumber = productData.cyfra || ""
		const productHeader = productData.naglowek || ""
		const productDescription = productData.opis || ""
		//TODO: element to select proper product 3d model
		const productSelected = (productData.obrot && productData.obrot[0] && productData.obrot[0]) || ""
		const productModelImages = (productSelected && productSelected.produktWizytowka && productSelected.produktWizytowka[0] && productSelected.produktWizytowka[0].zdjeciaPrezentacja3d) || []

		return (
			<StyledWrapper>
				<GridBackground>
				<TextContent>
					<ProductNumber className="font-size-h10h font-weight-bold color-white">{productNumber}</ProductNumber>
					<ProductHeader className="font-size-h6 font-weight-semiBold color-white">{productHeader}</ProductHeader>
				</TextContent>
				<ProductDescription className="font-size-h10 font-weight-regular line-height-7 color-white"
														dangerouslySetInnerHTML={getHtmlFromRedactorField(productDescription)}/>
					{productModelImages.length > 0 && <ModelWrapper>
						<RotatableImage productModelImages={productModelImages}/>
					</ModelWrapper>
					}

				</GridBackground>
			</StyledWrapper>
		)
	}
}

export default Product3dPresentation
