import React from "react"
import styled from "styled-components"
import getSingleImage from "../../utils/getSingleImage"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import ButtonBlueBorderWhite from "../common/buttons/Button"
import posed, { PoseGroup } from "react-pose"

const PictogramsGeneralWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.stringBlack};
  color: ${({ theme }) => theme.colors.stringWhite};
  min-height: 474px;
  padding: 106px 165px 194px 137px;
  position: relative;
  
  @media (max-width: 1400px){
    padding: 106px 72px 194px 36px;
  }
  @media (max-width: 999px){
    padding: 43px 36px 100px 36px;
  }
`

const HeaderPictograms = styled.div`
  width: 65px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.f15};
`

const BoxForPictograms = styled.div`
	position: relative;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  opacity: 1;
  cursor: pointer;
 	transition: visibility 0s, opacity 0.5s linear, padding .2s linear;
 	width: 50%;
 	
 	@media (min-width: 768px){
		width: 33%;
  }
 	
 	@media (min-width: 1200px){
		width: 100%;
  }

  //&.invisible {
  //	visibility: hidden;
  //	opacity: 0;
  //}
  &.visible {
  	//order: -1;
  	visibility: visible;
  	opacity: 1;
  }

	&:hover {
		#description {
			span {
				padding-bottom: 5px;
				border-bottom: 3px solid ${({ theme }) => theme.colors.stringWhite};
			}
		}
	}
	
  @media (min-width: 1200px){
 		position: unset;
    margin: 90px 0 0 0;
    padding: 0;
    width: 50%;
    
    &.invisible {
  		visibility: hidden;
  		opacity: 0;
  	}
  	&.visible {
  		order: -1;
  		padding-bottom: 0;
  	}
  }
`
const PictogramImage = styled.img`
  height: 68px;
  margin: 0 0 34px 0;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 0 0 0 23px;

  :before{
		content: "";
		width: 8px;
		height: 8px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: ${({ theme }) => theme.colors.stringWhite}

  };

  @media (max-width: 375px){
    padding: 0
  }
`

const PictogramDescription = styled.div`
  text-align: center;
  margin-bottom: 65px;
  line-height: 2;
  font-size: ${({ theme }) => theme.paragraphs.p2};
  font-weight: ${({ theme }) => theme.font.bold};
  width: 100%;
  
  @media (min-width: 1200px){
    margin: 0;
  }
`

const PictogramWrapper = styled.div`
  display: flex;
     flex-flow: row wrap;
  margin-top: 95px;
 	width: 100%;
 	justify-content: space-between;

	@media (min-width: 768px){
		justify-content: unset;
    flex-flow: row wrap;
  }
  
  @media (min-width: 1200px){
  	justify-content: space-between;
    flex-flow: row;
    margin: 0;
  }
`

const ExtendedContentCentered = styled.div`
	position: relative;
	top: 0;
	width: calc(100vw - 120px);
	margin-left: ${props => props.keyOfBox%2 === 0 ? 0 : 'calc(-100% - 40px)'};
  
  @media (min-width: 768px){
  	margin-left: ${props =>  ((props.keyOfBox !== 0) && ((props.keyOfBox + 1)%2 === 0 || 1) && (props.keyOfBox%3 !== 0)  && ((props.keyOfBox + 1)%3 !== 0)) ? 'calc(-100% - 40px)' : (props.keyOfBox + 1)%3 === 0 ? 'calc(-200% - 80px)' : 0};
  }
  
  @media (min-width: 1000px){
  	width: calc(100vw - 150px);
  }
  
	@media (min-width: 1200px){
		margin-left: 0;
		position: absolute;
		top: calc(50% - 135px);
		width: calc(100% - 600px);
		left: unset;
		right: 0;
  }
`

const PosedModal = posed.div({
	enter: {
		opacity: 1,
		y: 0,
		x: 0,
		transition: {
			default: { duration: 250 },
		},
	},
	exit: {
		opacity: 0,
		x: '100%',
		y: 0,
		transition: { duration: 250 },

	},
})

const PictogramExtendedWrapper = styled(PosedModal)`
	position: relative;
	border: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-radius: 7px;
	right: 0;
	padding: 40px 0;
	display: flex;
	align-items: center;
	margin-bottom: 50px;
			
	img {
		max-height: 230px;
		padding-right: 100px;
		margin: 0 auto;
	}
	
	@media (min-width: 1200px){
		right: 140px;
		min-height: 270px;
		padding: 0;
		margin-bottom: 0;
  }
`

const CloseIcon = styled.div`
	position: absolute;
  right: -50px;
  top: -50px;
  width: 23px;
  height: 23px;
  opacity: 1;
  cursor: pointer;
  transition: transform .1s linear;
  
  &:hover {
  	transform:scale(1.2);
	}
	&:before, &:after {
		position: absolute;
		left: 15px;
		content: ' ';
		height: 33px;
		width: 6px;
		background-color: ${({ theme }) => theme.colors.stringWhite};
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
`

const ExtendedContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-wrap: break-word;

	@media (min-width: 750px){
		flex-direction: row;
  }
`

const Column = styled.div`
	width: 100%;
	padding: 0 20px;
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.fontSizes.f14};
	line-height:  ${({ theme }) => theme.lineHeight.line1};

	&:first-child {
		padding-bottom: 20px;
		
		@media (min-width: 750px) {
			flex-direction: row;
		}
	}
	
	div {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-content: center;
	
		ul {
			padding-left: 15px;
			@media (min-width: 750px){
				padding-left: 40px;
  		}
		}
		
		li {
			list-style-type: square;
			padding-left: 15px;
		}
	
		h1 {
			font-size: ${({ theme }) => theme.headers.h13};
			line-height: ${({ theme }) => theme.lineHeight.line20};
			justify-self: center;
			align-self: center;
		}
		
		h4 {
		justify-self: center;
			align-self: center;
		}
	}
	
	@media (min-width: 750px){
		width: 50%;
  }
	
	@media (min-width: 1200px){
		padding: 0 60px;
  }
`

const InvisibleLink = styled.a`
	text-decoration: none;
	width: 100%;
	display: flex;
	justify-content: center;
`

const ButtonBlueBorderWhiteExtended = styled(ButtonBlueBorderWhite)`
	padding: 20px 50px 20px 40px;
	
	&:before {
		background-color: ${({ theme }) => theme.colors.stringBlack};
	}
	
	span {
		position: relative;
	
		&:after {
			content: '';
			position: absolute;
			right: -25px;
			top: 50%;
			transform: translateY(-50%);
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-left: 7px solid ${({ theme }) => theme.colors.stringWhite};
		}	
	}
	
	&:hover { 
		span {
			&:after {
				border-left: 7px solid ${({ theme }) => theme.colors.stringBlue};
			}	
		}
	}
`

class ProductPictograms extends React.Component {
	state = {
		activePictogram: null,
	}

	setActivePictogram = (key) => {
		this.setState({ activePictogram: key })
	}

	closeExtendedContent = (event) => {
		event.stopPropagation()
		this.setState({ activePictogram: null })
	}

	render() {
		const pictogramsData = this.props.data.produktPiktogramy
		const { activePictogram } = this.state
		const newPictogramData = pictogramsData.slice(1)
		const pictogramsTitle = this.props.data.produktPiktogramy[0].nazwa
		const downloadTextBtn = this.props.data.produktPiktogramy[0].pobierzZalacznik
		const pictogramArray = []

		newPictogramData.forEach(item => {
			pictogramArray.push({
				"description": item.opis,
				"pictogram": item.piktogram,
				"firstColumn": item.pierwszaKolumna,
				"secondColumn": item.drugaKolumna,
				"fileUrl": (item.plik && item.plik[0] && item.plik[0].url) || [],
			})
		})

		return (
			<PictogramsGeneralWrapper>
				<HeaderPictograms>{pictogramsTitle}</HeaderPictograms>
				<PictogramWrapper>
					{pictogramArray.map((pictogram, key) =>
						<React.Fragment key={key}>
							<BoxForPictograms onClick={() => this.setActivePictogram(key)}
																className={activePictogram === key ? "visible" : activePictogram !== null ? "invisible" : ""}>
								<PictogramImage className="hover-image" src={getSingleImage(pictogram.pictogram)} alt={pictogram.pictogram[0].altText}/>
								<PictogramDescription id="description"><span>{pictogram.description}</span></PictogramDescription>
								<ExtendedContentCentered keyOfBox={key}>
									<PoseGroup>
										{(activePictogram === key) &&
										<PictogramExtendedWrapper key="pictogram-extended-modal">
											<CloseIcon onClick={(event) => this.closeExtendedContent(event, key)}/>
											<ExtendedContentWrapper>
												{pictogram.firstColumn !== null &&
												<Column dangerouslySetInnerHTML={getHtmlFromRedactorField(pictogram.firstColumn)}/>}
												{pictogram.secondColumn !== null &&
												<Column dangerouslySetInnerHTML={getHtmlFromRedactorField(pictogram.secondColumn)}/>}
												{pictogram.fileUrl.length > 0 &&
												<InvisibleLink href={pictogram.fileUrl} target="_blank">
													<ButtonBlueBorderWhiteExtended><span>{downloadTextBtn}</span></ButtonBlueBorderWhiteExtended>
												</InvisibleLink>
												}
											</ExtendedContentWrapper>
										</PictogramExtendedWrapper>}
									</PoseGroup>
								</ExtendedContentCentered>
							</BoxForPictograms>
						</React.Fragment>,
					)
					}
				</PictogramWrapper>
			</PictogramsGeneralWrapper>

		)
	}
}

export default ProductPictograms
