import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Container = styled.div`
	margin: 0 auto;
	padding: 0 140px;
	width: 100%;
	
	@media (max-width: 1400px) {
		padding: 0 35px;
	};
`;

const PageContainer = ({ children }) => {
	return (
		<Container>{children}</Container>
	)
}

PageContainer.propTypes = {
	children: PropTypes.node.isRequired,
}

export default PageContainer



