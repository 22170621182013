import React from "react"
import styled from "styled-components"
import SingleNewsBanner from "./SingleNewsBanner"
import SingleNewsAuthorDataInfo from "./SingleNewsAuthorDataInfo"
import { LinkButtonBold } from "../common/buttons/ButtonLinkStyle"
import getSlug from "../../utils/getSlug"
import { Link } from "gatsby"
import PageContentExtendedContent from "../pageContent/pageContentExtendedContent"
import getDateInProperFormat from "../../utils/getDateInProperFormat"
import SeeAlso from "../seeAlso/seeAlso"

const SingleNewsWrapper = styled.div`
	position: relative;
	padding: 170px 35px 70px;
	
	@media (min-width: 1000px) {
		padding: 110px 200px 130px;
	}
	@media (min-width: 1400px) {
		padding: 110px 335px 130px;
	}
`

const LeftInfoWrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	
	@media (min-width: 1000px) {
		top: 120px;
		left: 35px;
	}
		@media (min-width: 1400px) {
		top: 120px;
		left: 140px;
	}
`

const BottomInfoWrapper = styled.div`
	margin-top: 50px;
`

const LinkButtonBoldExtended = styled(LinkButtonBold)`
	width: 100%;
	background-color: ${({ theme }) => theme.colors.stringLightGray};
	margin-bottom: 50px;
	margin-left:-35px;
	text-align: left;
	padding-left: 35px;
	text-decoration: none;
	
	@media (min-width: 1000px) {
		width: fit-content;
		background-color: transparent;
		margin-bottom: 40px;
	}
`

const SingleNewsHeader = styled.h1`
	font-size: ${({ theme }) => theme.headers.h6};
	font-weight: ${({ theme }) => theme.font.bold};
	color: ${({ theme }) => theme.colors.stringBlack};
	display: flex;
	flex-wrap: wrap;
	hyphens: auto;
	word-break: break-word;

	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.headers.h4};
		margin-bottom: 45px;
	}
`

class SingleNews extends React.Component {
	render() {
		const { activeSite } = this.props
		const bannerData = (this.props.data && this.props.data.aktualnoscBaner) || []
		const data = (this.props.data && this.props.data.trescPodstrony) || []
		const seeAlsoData = (this.props.data && this.props.data.zobaczRowniez) || []
		const createdDate = (this.props.data && this.props.data.postDate) || ""
		const dateInfo = getDateInProperFormat(activeSite, createdDate, true)
		const header = (this.props.data && this.props.data.aktualnoscOgolneInformacje && this.props.data.aktualnoscOgolneInformacje[0] && this.props.data.aktualnoscOgolneInformacje[0].nazwa) || ""
		const author = (this.props.data && this.props.data.aktualnoscOgolneInformacje && this.props.data.aktualnoscOgolneInformacje[0] && this.props.data.aktualnoscOgolneInformacje[0].autor) || ""

		const translationsAllPosts = (this.props.translationsData && this.props.translationsData.tlumaczenieWszystkieWpisy && this.props.translationsData.tlumaczenieWszystkieWpisy[0]) || []
		const allNewsBtn = (translationsAllPosts.wszystkieWpisy) || ""
		const allNewsSlugBtn = (translationsAllPosts.linkowanie && translationsAllPosts.linkowanie[0] && translationsAllPosts.linkowanie[0].slug) || ""

		return (
			<>
				<SingleNewsBanner data={bannerData}/>
				<SingleNewsWrapper>
					<LeftInfoWrapper>
						<LinkButtonBoldExtended as={Link} to={`/${getSlug(allNewsSlugBtn, activeSite)}`}>{allNewsBtn}</LinkButtonBoldExtended>
						<SingleNewsAuthorDataInfo dateInfo={dateInfo} author={author}/>
					</LeftInfoWrapper>
					<SingleNewsHeader>{header}</SingleNewsHeader>
					<PageContentExtendedContent data={data}/>
					<BottomInfoWrapper>
						<SingleNewsAuthorDataInfo dateInfo={dateInfo} author={author}/>
					</BottomInfoWrapper>
				</SingleNewsWrapper>
				<SeeAlso zobaczRowniez={seeAlsoData} activeSite={activeSite}/>
			</>
		)
	}
}

export default SingleNews
