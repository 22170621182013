import React from "react"
import styled from "styled-components"


const ButtonBlueBorderWhite = styled.button`
	position: relative;
	min-width: 185px;
	margin: 0;
	border: 3px solid ${({ theme }) => theme.colors.stringWhite};
	border-radius: 7px;
	color: ${({ theme }) => theme.colors.stringWhite};
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.paragraphs.p2};
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	padding: 20px 0;
	
	:before {
		content: '';
		position: absolute;
		bottom: -3px;
		right: 15px;
		width: 12px;
		height: 3px;
		background-color: ${({ theme }) => theme.colors.stringBlue};
		transition: margin 0.5s linear
	};
	
	:hover {
		outline: none;
		cursor: pointer;
		background-color: ${({ theme }) => theme.colors.stringWhite};
		color: ${({ theme }) => theme.colors.stringBlue};
	};

	:hover :before{
		//margin: 0 78% 0 0;
		margin-right: calc(100% - 40px);
	};

`


export const ButtonWhiteBlackBackground = styled(ButtonBlueBorderWhite)`
	border: 3px solid ${({ theme }) => theme.colors.stringWhite};
  color: ${({ theme }) => theme.colors.stringWhite};
  margin: 0;

	:before {
		background-color: ${({ theme }) => theme.colors.stringBlack};
	}
	
	:hover {
		background-color: ${({ theme }) => theme.colors.stringWhite};
		color: ${({ theme }) => theme.colors.stringBlack};
	};
`
export const ButtonWhiteBorderBlue = styled(ButtonBlueBorderWhite)`
	border: 3px solid ${({ theme }) => theme.colors.stringBlue};
  color: ${({ theme }) => theme.colors.stringBlue};
  margin: 0;

	:before {
		background-color: ${({ theme }) => theme.colors.stringWhite};
	}
	
	:hover {
		background-color: ${({ theme }) => theme.colors.stringBlue};
		color: ${({ theme }) => theme.colors.stringWhite};
	};

`


export default ButtonBlueBorderWhite
