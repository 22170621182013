import React from "react"
import styled from "styled-components"
import Headers from "../common/headers/Headers"
import getSingleImage from "../../utils/getSingleImage"
import Paragraphs from "../common/paragraphs/Paragraphs"

const AboutUsBannerWrapper = styled.div`
  height: 100%;
  padding: 150px 0 0 0;

  @media (max-width: 999px){
    padding: 77px 0 0 0
  }
`
const AboutUsBannerContent = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
	width: 100vw;
	position: relative;
	//padding: 177px 35px 0 35px;
	padding: 77px 75px 0 35px;
	
	@media (min-width: 375px) {
		padding: 77px 36px 0 36px;
	}
	@media (min-width: 1000px) {
		flex-direction: row;
		justify-content: flex-start;
		padding: 77px 75px 0 35px;
	}
`

const AboutUsBannerBackground = styled.div`
	display: none;
	
	@media (min-width: 1000px){ 
		display: block;
		position: absolute;
		top: 0;
		left: 930px;
		background-color: ${({ theme }) => theme.colors.stringWhite};
		width: 400px;
		z-index: 0;
	}
	
	@media (min-width: 1400px){ 
		width: 100%;
		max-width: 560px;
	}
	 
	@media (min-width: 1560px){
		right: 0;
		left: unset;
		width: 560px;
		max-width: unset;
	
	@media (min-width: 2000px){
		width: 630px;
	}
	 @media (min-width: 2260px){
		width: 735px;
	}
`
const AboutUsTextWrapper = styled.div`
	position: relative;
	
	@media (min-width: 1000px) {
		padding-left: 105px;
	}
`
const AboutUsMainHeader = styled(Headers)`
	font-size: ${({ theme }) => theme.headers.h2};
	font-weight: ${({ theme }) => theme.font.bold};
	position: relative;	
	display: table;
  z-index: 1;
  padding: 0 31px 0 0;
  color: ${({ theme }) => theme.colors.stringBlue}; 
	
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		width: 8px;
		height: 8px;
		right: -10px;
		background-color: ${({ theme }) => theme.colors.stringBlue}; 
  }
  
  @media (max-width: 420px){
    font-size: ${({ theme }) => theme.headers.h6};
  }
`

const AboutUsDescription = styled(Paragraphs)`
  font-weight: ${({ theme }) => theme.font.light};
  font-size: ${({ theme }) => theme.paragraphs.p1};
	padding-top: 15px;
  width: 70%;
	
	@media (min-width: 1000px) {
		padding-top: 30px;
		font-size: ${({ theme }) => theme.paragraphs.p1};
	}
	@media (min-width: 420px) {
		padding-top: 30px;
    font-size: ${({ theme }) => theme.paragraphs.p2};
    width: 100%
	}
`

const LowerPartWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
    margin: 115px 0 0;
    
    @media (min-width: 665px) {
		 margin: 115px 0 80px 0;
		}
`

const AboutUsBlueSquerOnRight = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: flex-end;
`

const BlueSquereContent = styled.div`
    width: 84%;
    background-color: ${({ theme }) => theme.colors.stringBlue};
    padding: 100px 0 0 100px;

    @media (max-width: 999px){
        width: 100%;
        padding: 81px 35px 0 35px;
    }
`

const LowerHeader = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.headers.h4};
  font-weight: ${({ theme }) => theme.font.bold};
  
  @media (max-width: 420px){
    font-size: ${({ theme }) => theme.headers.h6};
  }

`
const LowerDescription = styled.div`
  color: ${({ theme }) => theme.colors.stringWhite};
  font-weight: ${({ theme }) => theme.font.regular};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  width: 65%;
  line-height: 2;
  margin: 40px 0 100px 0;

  @media (max-width: 768px){
    width: 100%
  }
`

const ImageOnBlueSquer = styled.div`
  max-height: 447px;
  min-height: 225px;
  width: 100%;
  padding: 0 0 0 138px;
  display: flex;
	flex-flow: row;
  position: relative;
  margin: 0 0 50px 0;
  
  @media (max-width: 999px){
    padding: 0;
  }
  @media (max-width: 664px){
    margin: 0 0 300px 0;
  }
  @media (max-width: 375px){
    margin: 0 0 450px 0;
  }
`

const ImageWrapper = styled.div`
	max-width: 670px;
  max-height: 447px;
  min-height: 225px;
  min-width: 338px;
  position: relative;
	
	@media (max-width: 375px){
		height: 225px;
		width: 338px;
	}
`

const ImageBaner = styled.img`
	width: 100%;
  height: 100%
  padding: 0;
`

const BlueSquereOnImage = styled.div`
  width: calc(100% - 670px);
  max-height: 321px;
	background-color: ${({ theme }) => theme.colors.stringBlue};
	position: relative;

  @media (max-width: 999px){
    max-height: 100px;
  }
  @media (max-width: 664px){
    width: 100%
  }
`

const CitationBox = styled.div`
  min-height: 376px;
  width: 375px;
  background-color: ${({ theme }) => theme.colors.stringBlack};
  position: absolute;
  bottom: -10%;
  right: -45%;
	z-index: 3;
	display: flex;
	flex-flow: column;
  padding: 45px;
  color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  font-weight: ${({ theme }) => theme.font.regular};
  font-style: italic;
	line-height: 2;

	@media (max-width: 934px){
		right: -25%;
    width: 339px;
	};
	@media (max-width: 814px){
		right: 0;
    width: 339px;
	};
	@media (max-width: 664px){
    // bottom: -295px;
    display: none;

	};
	@media (max-width: 375px){
    bottom: -390px;
	};

	
`
const CitationBox2 = styled.div`
  min-height: 376px;
  width: 339px;
  background-color: ${({ theme }) => theme.colors.stringBlack};
  position: absolute;
  bottom: -10%;
  right: -15%;
	z-index: 3;
	flex-flow: column;
  padding: 45px;
  color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  font-weight: ${({ theme }) => theme.font.regular};
  font-style: italic;
  line-height: 2;
  display: none;

	@media (max-width: 664px){
    bottom: -295px;
    right: 0;
    display: flex;

	};
	@media (max-width: 375px){
    bottom: -390px;
	};
`
const Citation = styled.div`
`

const CitationAuthor = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	margin: 50px 0 0 0;
	font-style: normal;
`
const CitationAuthorPosition = styled.div`
	font-style: normal;
`

class AboutUsBanner extends React.Component {

	render() {
		const AboutUsDataArrayOne = this.props.data.banerDlaONas.slice(0, -2)
		const AboutUsArrayOne = []

		AboutUsDataArrayOne.forEach(item => {
			AboutUsArrayOne.push({
				"header": (item && item.naglowek || ""),
				"description": (item && item.opis || ""),
			})
		})

		const AboutUsDataArrayTwo = this.props.data.banerDlaONas.slice(1, -1)
		const AboutUsArrayTwo = []

		AboutUsDataArrayTwo.forEach(item => {
			AboutUsArrayTwo.push({
				"header": (item && item.naglowek || ""),
				"description": (item && item.opis || ""),
			})
		})

		const AboutUsDataArrayThree = this.props.data.banerDlaONas.slice(2)
		const AboutUsArrayThree = []

		AboutUsDataArrayThree.forEach(item => {
			AboutUsArrayThree.push({
				"imgUrl": (item && item.zdjecie || ""),
				"citation": (item && item.cytat || ""),
				"author": (item && item.autor || ""),
				"possition": (item && item.stanowisko || ""),
			})
		})

		return (

			<AboutUsBannerWrapper>
				<AboutUsBannerContent>
					<AboutUsBannerBackground/>
					{AboutUsArrayOne.map((mainHederAboutUs, key) =>
						<AboutUsTextWrapper key={key}>
							<AboutUsMainHeader lassName="color-blue">{mainHederAboutUs.header}</AboutUsMainHeader>
							<AboutUsDescription
								className="color-blue font-weight-regular font-family-subheader">{mainHederAboutUs.description}</AboutUsDescription>
						</AboutUsTextWrapper>,
					)}
				</AboutUsBannerContent>
				<LowerPartWrapper>
					<AboutUsBlueSquerOnRight>
						{AboutUsArrayTwo.map((mainHederAboutUs, key) =>
							<BlueSquereContent key={key}>
								<LowerHeader>{mainHederAboutUs.header}</LowerHeader>
								<LowerDescription
									className="color-white font-weight-regular font-family-subheader">{mainHederAboutUs.description}</LowerDescription>
							</BlueSquereContent>,
						)}
					</AboutUsBlueSquerOnRight>
					{AboutUsArrayThree.map((mainHederAboutUs, key) =>
						<ImageOnBlueSquer key={key}>
							<ImageWrapper>
								<ImageBaner src={getSingleImage(mainHederAboutUs.imgUrl)} alt={mainHederAboutUs.imgUrl[0].altText}/>
								<CitationBox className="color-white font-weight-italic font-family-subheader">
									<Citation>
										{mainHederAboutUs.citation}

									</Citation>
									<CitationAuthor>{mainHederAboutUs.author}</CitationAuthor>
									<CitationAuthorPosition>{mainHederAboutUs.possition}</CitationAuthorPosition>
								</CitationBox>
							</ImageWrapper>
							<BlueSquereOnImage/>
							<CitationBox2 className="color-white font-weight-italic font-family-subheader">
								<Citation>
									{mainHederAboutUs.citation}

								</Citation>
								<CitationAuthor>{mainHederAboutUs.author}</CitationAuthor>
								<CitationAuthorPosition>{mainHederAboutUs.possition}</CitationAuthorPosition>
							</CitationBox2>
						</ImageOnBlueSquer>,
					)}
				</LowerPartWrapper>
			</AboutUsBannerWrapper>
		)
	}
}

export default AboutUsBanner
