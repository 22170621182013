import React, { Component } from "react"
import styled from "styled-components"
import circleShadow from "../rotableImage/elipse-shadow.png"

// higher values make mouse less sensitive
const pixelsPerDegree = 3

const ModelContent = styled.div`
	position: relative;
	z-index: 1;
	width: 100vw;
	display: flex;
	justify-content: center;
`

const ModelImg = styled.img`
	max-width: 100%;
	width: auto;
	height: auto;
	pointer-events: none;
	user-select: none;
		display: block;
	margin: 0 auto;
`

const CircleBg = styled.div`
	position: absolute;
	bottom: 20%;
	transform: translateY(50%);
	z-index: -1;
	border: 2px solid white;
	opacity: .9;
	border-radius: 100%;
	width: calc(100% + 100px);
	height: 25%;
	margin-left: -50px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	img {
		max-width: 100%;
	}
	@media (min-width: 1000px) {
		width: calc(100% + 140px);
		margin-left: -70px;
	}
`

const ArrowLeftBtn = styled.button`
	position: absolute;
	top: 50%;
	width: 50px;
	height: 50px;
	transform: translateY(-50%);
	left: -50px;
	background: transparent;
	border: none;
	cursor: pointer;
	z-index: 1000;
	display: flex;
	justify-content: flex-start;
	outline: none;
	-webkit-tap-highlight-color: transparent;

	&:hover {
		outline: none;
	}
	
	@media (min-width: 1000px) {
			left: -70px;
	}
`

const ButtonIconWrapper = styled.div`
	position: relative;
	width: 15px;
	height: 20px;
	
	@media (min-width: 1000px) {
		width: 30px;
		height: 40px;
	}
`

const ArrowRightBtn = styled(ArrowLeftBtn)`
	justify-content: flex-end;
	left: unset;
	right: -50px;
	
		@media (min-width: 1000px) {
			left: unset;
			right: -70px;
	}
`

const IconDot = styled.div`
	position: absolute;
	width: 4px;
	height: 4px;
	background-color: ${({ theme }) => theme.colors.stringWhite};
	
	@media (min-width: 1000px) {
		width: 8px;
		height: 8px;
	}
	
	&:nth-child(1) {
		top: 0;
		right: 4px;
		
		&.next {
			left: 4px;
			right: unset;
		}
	}
	
	&:nth-child(2) {
		top: 7px;
		left: 0;
			
		@media (min-width: 1000px) {
			top: 13px;
		}
		
		&.next {
			left: unset;
			right: 0;
		}
	}
	
	&:nth-child(3) {
		top: 17px;
		right: 0;
		
		@media (min-width: 1000px) {
			top: 33px;
		}
		
		&.next {
			left: 0;
			right: unset;
		}
	}
`

const ModelImgWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
`

const ImgWrapper = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`

class RotatableImage extends Component {
	state = {
		dragging: false,
		imageIndex: 0,
		dragStartIndex: 0,
		arrowClicked: false,
		numOfImages: this.props.productModelImages.length - 1,
	}

	rotateLeft = () => {
		if (this.state.imageIndex > 0) {
			this.setState({ imageIndex: this.state.imageIndex - 1 })
		} else
			this.setState({ imageIndex: this.state.numOfImages })
	}

	handlePrevArrowMouseDown = () => {
		this.setState({ arrowClicked: true })
		clearInterval(this.interval)
		this.interval = setInterval(() => {
			this.rotateLeft()
		}, 50)
	}

	rotateRight = () => {
		if (this.state.imageIndex < this.state.numOfImages) {
			this.setState({ imageIndex: this.state.imageIndex + 1 })
		} else
			this.setState({ imageIndex: 0 })
	}

	handleNextArrowMouseDown = () => {
		clearInterval(this.interval)
		this.setState({ arrowClicked: true })
		this.interval = setInterval(() => {
			this.rotateRight()
		}, 50)
	}

	handleArrowMouseUp = () => {
		this.setState({ arrowClicked: false })
		clearInterval(this.interval)
	}

	handleMouseDown = event => {
		event.preventDefault()
		event.persist()

		this.setState(state => ({
			dragging: true,
			dragStart: event.screenX,
			dragStartIndex: state.imageIndex,
		}))
	}

	handleTouchStart = event => {
		event.persist()
		if (!this.state.arrowClicked) {
			this.setState(state => ({
				dragging: true,
				dragStart: event.touches[0].screenX,
				dragStartIndex: state.imageIndex,
			}))
		}
	}

	handleTouchEnd = event => {
		this.setState({ dragging: false })
	}

	handleMouseUp = () => {
		this.setState({ dragging: false })
	}

	updateImageIndex = currentPosition => {
		const numOfImages = this.state.numOfImages
		const pixelsPerImage = pixelsPerDegree * (360 / numOfImages)
		const { dragStart, imageIndex, dragStartIndex } = this.state
		// pixels moved
		let dx = (currentPosition - dragStart) / pixelsPerImage
		let index = Math.floor(dx) % numOfImages
		if (index < 0) {
			index = numOfImages + index - 1
		}
		index = (index + dragStartIndex) % numOfImages
		if (index !== imageIndex) {
			this.setState({ imageIndex: index })
		}
	}

	handleMouseMove = event => {
		if (this.state.dragging && !this.state.arrowClicked) {
			this.updateImageIndex(event.screenX)
		}
	}

	handleTouchMove = event => {
		if (this.state.dragging && !this.state.arrowClicked) {
			this.updateImageIndex(event.touches[0].screenX)
		}
	}

	renderImage = (productModelImages) => {
		const { imageIndex } = this.state
		return (
			<>
				<ModelImg
					alt="Opus"
					src={productModelImages[imageIndex].url}
				/>
				{/*<div>{imageIndex}</div>*/}
				<CircleBg>
					<img alt="" src={circleShadow}/></CircleBg>
			</>

		)
	}
	render = () => {
		const { productModelImages } = this.props

		return (
			<ModelContent onMouseDown={this.handleMouseDown} onTouchStart={this.handleTouchStart}
										onTouchEnd={this.handleTouchEnd}
										onMouseUp={this.handleMouseUp} onMouseMove={this.handleMouseMove} onTouchMove={this.handleTouchMove}
										onMouseLeave={this.handleMouseUp}>
				<ModelImgWrapper>
					<ImgWrapper>
						{this.renderImage(productModelImages)}
					</ImgWrapper>
					<ArrowLeftBtn onMouseDown={this.handlePrevArrowMouseDown} onMouseUp={this.handleArrowMouseUp}
												onMouseOut={this.handleArrowMouseUp}
												onTouchStart={this.handlePrevArrowMouseDown} onTouchEnd={this.handleArrowMouseUp}>
						<ButtonIconWrapper>
							<IconDot/>
							<IconDot/>
							<IconDot/>
						</ButtonIconWrapper>
					</ArrowLeftBtn>
					<ArrowRightBtn onMouseDown={this.handleNextArrowMouseDown} onMouseUp={this.handleArrowMouseUp}
												 onMouseOut={this.handleArrowMouseUp}
												 onTouchStart={this.handleNextArrowMouseDown} onTouchEnd={this.handleArrowMouseUp}>
						<ButtonIconWrapper>
							<IconDot className="next"/>
							<IconDot className="next"/>
							<IconDot className="next"/>
						</ButtonIconWrapper>
					</ArrowRightBtn>
				</ModelImgWrapper>
			</ModelContent>
		)
	}
}

export default RotatableImage
