import React from "react"
import styled from "styled-components"
import Headers from "../common/headers/Headers"
import { LinkButtonBold } from "../common/buttons/ButtonLinkStyle"
import ButtonOnPictureNews from "../common/buttons/ButtonsOnPictureNews"
import getSingleImage from "../../utils/getSingleImage"
import getSlug from "../../utils/getSlug"
import getDateInProperFormat from "../../utils/getDateInProperFormat"
import { Link } from "gatsby"

const Wrapper = styled.div`
	padding: 70px 0 0;
	@media (min-width: 1000px) {
		padding: 337px 140px 100px;
	}
`

const ListOfNewsWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	
	@media (min-width: 1000px) {
		padding-top: 133px;
		flex-direction: row;
	}
`

const SingleNewsCardWrapper = styled.div`
	width: 100%;
	
	@media (min-width: 1000px) {
		width: 50%;
		margin-bottom: 105px;
		
		&:nth-child(odd){
			padding-right: 10px;
		}
		&:nth-child(even){
			padding-left: 10px;
		}
	}
		&:hover {
		.hover-button {
			display: flex;
			background-color: ${({ theme }) => theme.colors.stringWhite};
			&:before {
				background-image: none;
				cursor: pointer;
				background-color: ${({ theme }) => theme.colors.stringBlue};
  		}
		}
		.left {
			width: 16px;
		}
		.right {
				width: 155px;
		}
		.content{
			color: ${({ theme }) => theme.colors.stringBlue};
		}
	}
`

const SingleNewsCard = styled.div`
	position: relative;
	background-image: url(${props => props.back});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	cursor: pointer;
	height: 475px;

	@media (min-width: 1400px) {
		&:hover {
			background-image: none;
			background-color: ${({ theme }) => theme.colors.stringBlue};
		
			div.info {
				background-color: ${({ theme }) => theme.colors.stringBlack};
			}
			div.read-btn {
				display: flex;
			}
		}
	}
`

const ButtonWrapper = styled.div`
	position: absolute;
	bottom: 105px;
	left: 45px;
	width: 100%;
	
	@media (min-width: 1000px) {
		width: 125px;
	}
`

const InfoContent = styled.div`
	background-color: ${({ theme }) => theme.colors.stringBlue};
	width: 90%;
	height: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 85px 35px;
	
	@media (min-width: 1000px) {
		width: 295px;
		height: 295px;
		top: 50%;
		bottom: unset;
	}
	
`

const Date = styled.div`
	position: relative;
	color:  ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.fontSizes.f16};
	line-height: ${({ theme }) => theme.lineHeight.line11};
	font-weight: ${({ theme }) => theme.font.bold};
	
	&:before {
		content: '';
		width: 8px;
   	height: 8px;
   	position: absolute;
   	top: -20px;
   	left: 0;
   	background-color: ${({ theme }) => theme.colors.stringWhite};
	}
`

const Description = styled(Headers)`
	color:  ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.fontSizes.f25};
	line-height: ${({ theme }) => theme.lineHeight.line18};
	font-weight: ${({ theme }) => theme.font.regular};
	margin-top: 5px;
`
const HeaderMobile = styled(Headers)`
	position: relative;
	display: block;
	margin-bottom: 35px;
	margin-left: 35px;
	align-self: flex-start;
	text-align: left;
	
	&:before {
		content: '';
		width: 8px;
   	height: 8px;
   	position: absolute;
   	top: -20px;
   	left: 0;
   	background-color: ${({ theme }) => theme.colors.stringBlack};
	}
	
	&:first-letter {
	 	text-transform: uppercase;
	}
	
	@media (min-width: 1000px) {
		display: none;
	}
`

const NavigationButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 85px;
`

const LinkButtonBoldExtended = styled(LinkButtonBold)`
	width: 110px;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.stringBlack};
	font-size: ${({ theme }) => theme.fontSizes.f13};
	line-height: ${({ theme }) => theme.lineHeight.line14};
	margin-bottom: 85px;
	
	@media (min-width: 1000px) {
		margin-bottom: 0;
	}
	
	&.prev {
		padding-right: 20px;
		text-align: end;
		@media (min-width: 1000px) {
			padding-right: 75px;
		}
	}
	
	&.next {
		padding-left: 40px;
		@media (min-width: 1000px) {
			padding-left: 75px;
		}
	}
	&:hover, &:focus {
		color: ${({ theme }) => theme.colors.stringBlue};
	}
`

class ListOfNews extends React.Component {

	state = {
		shouldNewsHover: true,
	}

	constructor(props) {
		super(props)
		this.checkNewsHover = this.checkNewsHover.bind(this)
	}

	checkNewsHover() {
		if (window.innerWidth < 1400) {
			this.setState({ shouldNewsHover: false })
		} else {
			this.setState({ shouldNewsHover: true })
		}
	}

	componentDidMount() {
		this.checkNewsHover()
		window.addEventListener("resize", this.checkNewsHover)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.checkNewsHover)
	}

	render() {
		const { translationsData, newsAll, pageContext } = this.props
		const { shouldNewsHover } = this.state
		const activeSite = pageContext.site
		const allNewsArray = []
		const allNewsHeaderMobile = translationsData.tlumaczenieWszystkieAktualnosci || ""
		const prevPageBtn = translationsData.tlumaczeniePoprzedniaStrona || ""
		const nextPageBtn = translationsData.tlumaczenieNastepnaStrona || ""
		const newsNumPages = pageContext.numPages | ""
		const newsCurrentPage = pageContext.currentPage | ""

		const isFirst = newsCurrentPage === 1
		const isLast = newsCurrentPage === newsNumPages
		const newsPathLang = activeSite === "stringEn" ? "en/" + pageContext.newsPath : pageContext.newsPath
		const prevPage = newsCurrentPage - 1 === 1 ? "/" : (newsCurrentPage - 1).toString()
		const nextPage = (newsCurrentPage + 1).toString()

		newsAll.forEach(item => {
			const singleNewsData = (item && item.aktualnoscWizytowka) || []
			allNewsArray.push({
				"image": (singleNewsData && singleNewsData[0] && singleNewsData[0].zdjecie) || "",
				"date": (item.postDate && getDateInProperFormat(activeSite, item.postDate, true)) || "",
				"description": (singleNewsData && singleNewsData[1] && singleNewsData[1].opis) || "",
				"slugBtn": (item.slug) || "",
			})
		})
		const readTextBtn = (translationsData && translationsData.tlumaczenieCzytaj) || ""

		return (
			<Wrapper>
				<HeaderMobile className="font-size-h9 font-weight-bold color-black">{allNewsHeaderMobile}</HeaderMobile>
				<ListOfNewsWrapper id="list-of-news">
					{shouldNewsHover && allNewsArray.map((item, key) =>
						<SingleNewsCardWrapper key={key} as={Link} to={`/${getSlug(item.slugBtn, activeSite)}`}>
							<SingleNewsCard back={getSingleImage(item.image)}>
								<ButtonWrapper className="read-btn">
										<ButtonOnPictureNews readTextBtn={readTextBtn} className="hover-button"/>
								</ButtonWrapper>
								<InfoContent className="info">
									<Date>{item.date}</Date>
									<Description>{item.description}</Description>
								</InfoContent>
							</SingleNewsCard>
						</SingleNewsCardWrapper>,
					)}

					{!shouldNewsHover && allNewsArray.map((item, key) =>
						<SingleNewsCardWrapper key={key} as={Link} to={`/${getSlug(item.slugBtn, activeSite)}`}>
							<SingleNewsCard back={getSingleImage(item.image)}>
								<InfoContent className="info">
									<Date>{item.date}</Date>
									<Description>{item.description}</Description>
								</InfoContent>
							</SingleNewsCard>
						</SingleNewsCardWrapper>,
					)}

					<NavigationButtonsWrapper>
						{!isFirst && (
							<LinkButtonBoldExtended as={Link} to={`/${newsPathLang}/${prevPage}/#list-of-news`} className="prev"
																			rel="prev">{prevPageBtn}</LinkButtonBoldExtended>

						)}

						{!isLast && (
							<LinkButtonBoldExtended as={Link} to={`/${newsPathLang}/${nextPage}/#list-of-news`} className="next"
																			rel="next">{nextPageBtn}</LinkButtonBoldExtended>
						)}
					</NavigationButtonsWrapper>

				</ListOfNewsWrapper>
			</Wrapper>
		)
	}
}

export default ListOfNews
