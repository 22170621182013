import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import { connect } from "react-redux"
import { changeLogoColor } from "../../state/app"
import Headers from "../common/headers/Headers"
import getSlug from "../../utils/getSlug"
import getSingleImage from "../../utils/getSingleImage"
import { Link } from "gatsby"

const Wrapper = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.colors.stringBlue};
	padding: 200px 35px 130px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	@media (min-width: 1000px) {
		margin-top: -5px;
	}
`

const PhotoBg = styled.div`
		background-image: url(${props => props.back});
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
`

const Code = styled(Headers)`
	font-size: ${({ theme }) => theme.fontSizes.f3};
	position: relative;
	display: table;
	
	&:before {
		content: '';
		width: 15px;
   	height: 15px;
   	position: absolute;
   	top: 0;
   	right: -50px;
   	background-color: ${({ theme }) => theme.colors.stringWhite};
	}

	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.fontSizes.f22};
		margin-top: -5px;
		&:before {
			content: '';
			width: 30px;
   		height: 30px;
		}
	}
`

const LinkStyled = styled(Link)`
	position: relative;
	z-index: 1;
	margin-top: 20px;
	font-size: ${({ theme }) => theme.fontSizes.f14};
	color: ${({ theme }) => theme.colors.stringWhite};
	font-weight: ${({ theme }) => theme.font.light};
	text-decoration: none;
	text-align: center;
	line-height: ${({ theme }) => theme.lineHeight.line7}; 
	
	strong {
	margin-bottom: 1px;
	border-bottom: 2px solid ${({ theme }) => theme.colors.stringWhite};
	}
`

const Description = styled(Headers)`
	position: relative;
	z-index: 1;
	font-size: ${({ theme }) => theme.fontSizes.f12};
	margin-top: 40px;
	text-align: center;
	
	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.fontSizes.f26};
	}
`
class String404 extends React.Component {
	render() {
		const { dispatch, activeSite } = this.props
		dispatch(changeLogoColor(true))
		const data = (this.props.data && this.props.data.string404 && this.props.data.string404[0]) || ""
		const code = data.kod || ""
		const description = data.opis || ""
		const linkText = data.przyciskOpis || ""
		const linkSlug = (data.przyciskLink && data.przyciskLink[0] && data.przyciskLink[0].slug) || ""
		const bgPhotoUrl = data.zdjecie || ""

		return (
			<>

			<Wrapper>
				<PhotoBg back={getSingleImage(bgPhotoUrl)}/>
				<Code className="color-white font-weight-semiBold">{code}</Code>
				<Description className="color-white font-weight-light"> {description}</Description>
				<LinkStyled to={`/${getSlug(linkSlug, activeSite)}`} dangerouslySetInnerHTML={getHtmlFromRedactorField(linkText)}/>
			</Wrapper>
				</>
		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(String404)
