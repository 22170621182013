import React from "react"
import styled from "styled-components"

const LoaderBlend = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	z-index: 100000;
	overflow: hidden;
	
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: .95;
		background-color: ${({ theme }) => theme.colors.stringBlack};
	}
`

const CustomLoader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height :100px;
	width: 100px;
	display: flex;
	justify-content: space-between;
`

const Square = styled.div`
	width: 25px;
	height:25px; 
	background-color: white;
	animation: square-animation .5s alternate infinite ease-in;
	transform: scale(.5);
	
	&:nth-child(2) {
		animation-delay: .5s;
	}
	
	@keyframes square-animation {
  	0%   {transform: scale(.5);}
  	100% {transform: scale(1);}
	}
`

const CustomSpinner = () => {

	return (
		<>
			<LoaderBlend>
				<CustomLoader>
					<Square/>
					<Square/>
					<Square/>
				</CustomLoader>
			</LoaderBlend>
		</>
	)
}

export default CustomSpinner
