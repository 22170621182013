import React from "react"
import styled from "styled-components"

const CitationStyled = styled.div`
	padding: 30px 20px 50px 0;
	font-size: ${({ theme }) => theme.fontSizes.f10};
	line-height: ${({ theme }) => theme.lineHeight.line12};
	font-style: italic;
	color: ${({ theme }) => theme.colors.stringBlack};
	display: flex;
	text-align: center;

	@media (min-width: 1000px) {
		padding: 50px 20px 50px 0;
		margin: 0 -150px 0 -90px;
		font-size: ${({ theme }) => theme.fontSizes.f24};
	}
	
	&.czarny {
		color: ${({ theme }) => theme.colors.stringBlack};
	}
	&.niebieski {
		color: ${({ theme }) => theme.colors.stringBlue};
	}
`

class Citation extends React.Component {
	render() {
		const data = (this.props.data) || []
		const citation = data.cytat || ""
		const color = data.kolor || ""
		return (
			<CitationStyled className={color}>{citation}</CitationStyled>
		)
	}
}

export default Citation
