import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import getSingleImage from "../../utils/getSingleImage"

const MainInterfaceWrapper = styled.div`
	max-height: 1088px;
	background-color: ${({ theme }) => theme.colors.stringWhite};
	color: ${({ theme }) => theme.colors.stringBlack};
	padding: 119px 165px 162px 137px;
	display: flex;
	flex-flow: column;
	align-items: center;
	position: relative;
	
	@media (max-width: 1400px){
		padding: 86px 72px 108px 36px;
	
	}
	@media (max-width: 999px){
		padding: 43px 36px 104px 36px;
	}
`

const HeaderInterface = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	margin: 0;
	
	@media (max-width: 375px){
		justify-content: flex-end;
		flex-flow: column;
		margin: 0 0 100px 0
	}
`

const InterfaceNumber = styled.div`
	width: 10%;
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.headers.h10h};
	margin: 0;
	padding: 10px 0 0 0;
	
	@media (max-width: 375px){
		text-align: right;
		margin: 0 0 34px 0;
		width: 100%;
		padding: 0;
	}
`

const InterfaceTitle = styled.div`
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
	align-items: flex-end;
`

const InterfaceDescription = styled.div`
	padding: 46px 0 66px 0;
	font-size: ${({ theme }) => theme.paragraphs.p14};
	font-weight: ${({ theme }) => theme.font.regular};
	text-align: right;
	max-width: 668px;
	line-height: 1.5
`

const Title = styled.div`
	text-align: right;
	font-weight: ${({ theme }) => theme.font.semiBold};
	font-size: ${({ theme }) => theme.headers.h4};

	@media (max-width: 375px){
		font-size: ${({ theme }) => theme.headers.h6};
	}
`

const InterfaceBox = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;

	@media (max-width: 768px){
		flex-flow: column
	}
	`

const LeftBoxPanel = styled.ul`
	max-width: 247px;
	padding: 0 41px 0 41px;
	display: flex;
	flex-flow: column;
	margin: 0 78px 0 0;

	.active {
		color: ${({ theme }) => theme.colors.stringBlue};
		font-size: ${({ theme }) => theme.fontSizes.f14};
	}

	@media (max-width: 768px){
		display: none;
	}
`

const LeftBoxItem = styled.li`
	position: relative;
	// padding: 0 100px 35px 21px;
	height: 50px;
	color: #C7C7C7;
	cursor: pointer;
	font-size: ${({ theme }) => theme.fontSizes.f17};
	font-family: ${({ theme }) => theme.font.headerFont};
	line-height: 1.5;
	padding: 0 61px 0 41px;
	margin: 0 0 40px 0;
	letter-spacing: 5px;
	display: flex;
	align-items: center;

	:before{
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background-color: ${({ theme }) => theme.colors.lighterGrey};
	}

	&.active{
		color: ${({ theme }) => theme.colors.stringBlue};
		:before{
			background-color: ${({ theme }) => theme.colors.stringBlue};
			//top: 15px
		}
	}
`

const InterfaceImageBox = styled.div`
	max-width: 953px;
	max-height: 591px;
	border-radius: 20px;
	border: 7px solid ${({ theme }) => theme.colors.stringBlue};
	background-color: ${({ theme }) => theme.colors.stringBlue};
	display: flex;
`

const StyleDrawing = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 10px;
`

const LeftBoxPanelMobile = styled.div`
	width: 100%;
	padding: 0 41px 0 41px;
	flex-flow: column;
	display: none;
	
	.active {
		color: ${({ theme }) => theme.colors.stringBlue};
		font-size: ${({ theme }) => theme.fontSizes.f14};
		display: flex;
		align-items: center;
	};

	@media (max-width: 768px){
		flex-flow: row;
		padding: 0 41px 22px 0;
		max-width: 196px;
		// overflow: hidden;
		display: flex;
	}
`

const LeftBoxItemMobile = styled.div`
	position: relative;
	//padding: 0 100px 35px 21px;
	padding-left: 10px;
	height: 50px;
	color: #C7C7C7;
	cursor: pointer;
	font-size: ${({ theme }) => theme.fontSizes.f17};
	line-height: 1.5;
	display: none;
	min-width: 180px;

	:active{
		color: ${({ theme }) => theme.colors.stringBlue};
	}
	
	:before{
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		top: 5px;
		left: 0;
		background-color: ${({ theme }) => theme.colors.stringBlue};

		@media (max-width: 768px){
			display: none
		}
	}

	// :after{
	// 	content: "";
	// 	width: 0;
	// 	height: 0;
	// 	border-top: 5px solid transparent;
	// 	border-left: 10px solid ${({ theme }) => theme.colors.stringBlue};
	// 	border-bottom: 5px solid transparent;
	// 	position: absolute;
	// 	bottom: 17px;
	// 	right: 0;
	// };
`

const LeftBoxItemMobileTitle = styled.div`
position: relative;

	:after{
		content: "";
		width: 0;
		height: 0;
		border-top: 5px solid transparent;
		border-left: 10px solid ${({ theme }) => theme.colors.stringBlue};
		border-bottom: 5px solid transparent;
		position: absolute;
		top: 50%;
		transform: translateY(-60%);
		right: -50px;
	};
`

class ProductInterface extends React.Component {
	intervalID = 0;
	numOfImg = 0;

	state = {
		activeImgIndex: 0,
		isMobile: false,
	}

	constructor(props) {
		super(props)
		this.checkDevice = this.checkDevice.bind(this)
		this.slideShow = this.slideShow.bind(this)
	}

	checkDevice() {
		if (window.innerWidth < 768) {
			this.setState({ isMobile: true })
		} else {
			this.setState({ isMobile: false })
		}
	}

	slideShow() {
		const {activeImgIndex} = this.state
		if (activeImgIndex < this.numOfImg - 1 ) {
			this.setState({ activeImgIndex: activeImgIndex + 1 })
		}
		else {
				this.setState({ activeImgIndex: 0 })
		}

	}

	componentDidMount() {
		this.checkDevice()
		window.addEventListener("resize", this.checkDevice)

		this.intervalID = setInterval(this.slideShow, 2000);

	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.checkDevice)
		clearInterval(this.intervalID);
	}

	changeImg = (descriptionArray) => {
		clearInterval(this.intervalID);
		if (this.state.activeImgIndex < descriptionArray.length - 1) {
			this.setState({ activeImgIndex: this.state.activeImgIndex + 1 })
		} else this.setState({ activeImgIndex: 0 })
	}

	changeImage = (key) => {
		clearInterval(this.intervalID);
		this.setState({ activeImgIndex: key })
	}

	render() {
		const { isMobile } = this.state
		const interfaceData = this.props.data.produktInterface
		const interfacetitle = this.props.data.produktInterface[0].naglowek
		const interfacetnumber = this.props.data.produktInterface[0].cyfra
		const interfacetdescription = this.props.data.produktInterface[0].opis


		const newInterfaceData = interfaceData.slice(1)

		const descriptionsArray = []
		const imageArray = []

		this.numOfImg = newInterfaceData.length

		newInterfaceData.forEach(item => {
			descriptionsArray.push({
				"nazwa": item.nazwa,

			})
		})

		newInterfaceData.forEach(item => {
			imageArray.push({
				"imgUrl": item.zdjecie,

			})
		})


		return (
			<MainInterfaceWrapper>
				<HeaderInterface>
					<InterfaceNumber>{interfacetnumber}</InterfaceNumber>
					<InterfaceTitle>
						<Title>
							{interfacetitle}
						</ Title>
						<InterfaceDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(interfacetdescription)}/>
					</InterfaceTitle>
				</HeaderInterface>
				<InterfaceBox>
					<LeftBoxPanelMobile>
						{descriptionsArray.map((productInterface, key) =>
							<LeftBoxItemMobile className={key === this.state.activeImgIndex ? "active" : ""}
																 onClick={this.changeImg.bind(this, descriptionsArray)}
																 key={key}>
								<LeftBoxItemMobileTitle>
								{productInterface.nazwa}
								</LeftBoxItemMobileTitle>
							</LeftBoxItemMobile>,
						)}
					</LeftBoxPanelMobile>
					<LeftBoxPanel>
						{!isMobile && descriptionsArray.map((productInterface, key) =>
							<LeftBoxItem className={key === this.state.activeImgIndex ? "active" : ""}
													 onClick={this.changeImage.bind(this, key)} key={key}>{productInterface.nazwa}</LeftBoxItem>,
						)}
					</LeftBoxPanel>
					<InterfaceImageBox>
						<StyleDrawing src={getSingleImage(imageArray[this.state.activeImgIndex].imgUrl)} alt={imageArray[0].altText}/>
					</InterfaceImageBox>
				</InterfaceBox>
			</MainInterfaceWrapper>
		)
	}
}

export default ProductInterface

