import React from "react"
import styled from "styled-components"
import Headers from "../common/headers/Headers"

const HeaderStyled = styled(Headers)`
	font-size: ${({ theme }) => theme.headers.h6};
	font-weight: ${({ theme }) => theme.font.bold};
	color: ${({ theme }) => theme.colors.stringBlack};
	display: flex;
	flex-wrap: wrap;
	hyphens: auto;
	word-break: break-word;

	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.headers.h4};
		margin-bottom: 45px;
	}
	
	&.czarny {
		color: ${({ theme }) => theme.colors.stringBlack};
	}
	&.niebieski {
		color: ${({ theme }) => theme.colors.stringBlue};
	}
`

class Header extends React.Component {
	render() {
		const data = (this.props.data) || []
		const header = data.naglowek || ""
		const color = data.kolor || ""

		return (
			<HeaderStyled className={color}>{header}</HeaderStyled>
		)
	}
}

export default Header
