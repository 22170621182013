import React from "react"
import styled from "styled-components"
import {ButtonWhiteBlackBackground} from "../common/buttons/Button"
import {ContentWrapperRow} from "../common/pageContainer/ContentContainer"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import getSingleImage from "../../utils/getSingleImage"
import StyledLink from "../common/buttons/ButtonLinkStyle"
import getSlug from "../../utils/getSlug"

const Content = styled.header`
  background-color: ${({ theme }) => theme.colors.stringBlue};
  min-height:595px;
  padding: 0 0 0 50px;
  margin-top: -6px;
  display: flex;
  justify-content: flex-end;
  align-items: space-beetween;

  @media (max-width: 1400px){

    padding: 0
  };
`;

const ContentBlackWrapper = styled(ContentWrapperRow)`
  background-color: ${({ theme }) => theme.colors.stringBlack};
  max-width: 100vw;
  width: 100%;
  min-height:595px;
  padding: 0;
  margin: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 89px 140px 100px 90px;
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  
  @media (max-width: 1400px){
    width: 100%;
    padding: 89px 35px 100px 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  @media (max-width: 800px){
    width: 100%;
    flex-flow: column-reverse;
    padding: 59px 35px 71px 35px;
  }
`;

const StyleDrawing = styled.img`
  width: 180px;
  height: 89px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;

  @media (max-width: 800px){
    margin: 0 0 58px 0
  };
`;

const Header = styled.h1`
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.headers.h2};
	line-height:  ${({ theme }) => theme.headers.h2};
  padding: 0 0 45px 0;
  color: ${({ theme }) => theme.colors.stringWhite};
  
  @media (max-width: 970px){
    padding: 50px 0 83px 0;
    font-size: ${({ theme }) => theme.headers.h3};
    line-height:  ${({ theme }) => theme.headers.h3};
  };
  @media (max-width: 375px){
    font-size: ${({ theme }) => theme.headers.h6};
    line-height: ${({ theme }) => theme.headers.h6};
    padding: 0 0 27px 0
  };
`;

const Text = styled.div` 
  padding: 0 0 138px 0;
  font-weight: ${({ theme }) => theme.font.light};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  color: ${({ theme }) => theme.colors.stringWhite};

  @media (max-width: 800px){
    padding: 0 0 83px 0
  };
`;

const Paragraph = styled.div`
  width:65%;
  font-weight: ${({ theme }) => theme.font.light};
  font-size: ${({ theme }) => theme.paragraphs.p2};
  color: ${({ theme }) => theme.colors.stringWhite};
  line-height: 28px;

  @media (max-width: 800px){
    width: 100%;
  }

`;

class About extends React.Component {
  render(){
  	const activeSite = this.props.activeSite;
    const aboutData = this.props.data.naglowekOpisIPrzycisk;

    const naglowek = ( aboutData && aboutData[0].naglowek ) || "";
    const opis = (aboutData && aboutData[0].opis) || "";
    const opisButton = (aboutData && aboutData[0].przyciskOpis) || "";
    const imgUrl = (aboutData && aboutData[0].sloganPoPrawej) || "";

    const linkButton = (aboutData && aboutData[0].przyciskLink[0].slug) || "";

    const linkButtonSlug = getSlug(linkButton,activeSite)

    return(
      <Content>
        <ContentBlackWrapper>
          <Paragraph className="font-size-p2 font-weight-light color-white">
            <Header className="font-size-h2">{naglowek}</Header>
						{/*<Text>{opis}</Text>*/}
            <Text dangerouslySetInnerHTML={getHtmlFromRedactorField(opis)} />
            <StyledLink to={`/${linkButtonSlug}`}>
              <ButtonWhiteBlackBackground to="" className="large">
                <span>{opisButton}</span>
              </ButtonWhiteBlackBackground>
            </StyledLink>
          </Paragraph>
          <StyleDrawing src={getSingleImage(imgUrl)} />
        </ContentBlackWrapper>
      </Content>
    )
  }
}
export default About
