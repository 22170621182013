import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import getSlug from "../../utils/getSlug"

const StyledLink = styled(Link)`
	cursor: pointer;
	text-decoration: none;
  color: white;
`

class InternalLink extends React.Component {

	render() {
		const props = this.props
		return (
			<StyledLink {...props} to={`/${getSlug(props.to)}`}>{props.children}</StyledLink>
		)
	}
}

export default InternalLink
