import React from "react"
import styled from "styled-components";
import { Link } from "gatsby"

const StyledLink = styled(props => <Link {...props} />)`
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  //padding: 0;
  display: flex;
  width: 185px;
`;

export const StyledLinkOnPicture = styled(StyledLink)`
  height: 60px;
	width: 185px;
	border-radius: 7px;
	color: ${({ theme }) => theme.colors.stringBlue};
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.paragraphs.p2};
	background-color: transparent;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	padding: 0;
	position: absolute;
`

export const StyledLinkOnMap = styled(StyledLink)`
  height: 60px;
	width: 266px
`

export const LinkButton = styled.button`
	margin-top: 35px;
	background-color: transparent;
	border: none;
	width: fit-content;
	display: table;
	color: ${({ theme }) => theme.colors.lightBlue};
	text-decoration: underline;
	font-size: ${({ theme }) => theme.fontSizes.f14};
	cursor: pointer;
	
	&:hover, &:focus {
		outline: none;
	}
`

export const LinkButtonBold = styled.button`
	background-color: transparent;
	border: none;
	padding: 0;
	width: fit-content;
	display: table;
	color: ${({ theme }) => theme.colors.stringBlue};
	font-size: ${({ theme }) => theme.fontSizes.f16};
	font-weight: ${({ theme }) => theme.font.bold};
	line-height: ${({ theme }) => theme.lineHeight.line4};
	cursor: pointer;
	
	&:hover, &:focus {
		outline: none;
	}
`
export default StyledLink
