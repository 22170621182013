import React from "react"
import styled from "styled-components"

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.f14};
  line-height: ${({ theme }) => theme.lineHeight.line9};
  color:${({ theme }) => theme.colors.stringWhite};
  font-weight: ${({ theme }) => theme.font.light};;
  
  @media (min-width: 1000px) {
		font-size:  ${({ theme }) => theme.fontSizes.f13};
	}
	
  textarea {
  min-height: 120px;
  resize: none;
  font-family: ${({ theme }) => theme.font.textFont};
  @media(min-width: 768px) {
    min-height: 200px;
  }
 }
`

const StyledInputWrapper = styled.div`
	position: relative;
	&:before {
		content: '';
		position: absolute;
		bottom: 4px;
		right: 20px;
		width: 12px;
		height: 13px;
		background-color: ${({ theme }) => theme.colors.stringBlue};
		z-index: 10;
    transition: margin 0.5s linear;
  };
  
	&:hover {
		&:before {
			margin-right: calc(100% - 50px);
		}
	}
  &.inputCareer, &.newMessageClass, &.inputCareerMessage {
    &:before{
      background-color: rgba(0, 0, 0, 1);
      height: 5px;
      bottom: 4px;
    };

    &:hover {
      &:before {
				margin-right: calc(100% - 50px);
      }
    }
  }

 &:focus-within, &:hover {
		&:before {
			margin-right: calc(100% - 50px);
		}
  }
`

const StyledInputWrapperTextarea = styled(StyledInputWrapper)`
	&:focus-within, &:hover {
		&:before {
					margin-right: calc(100% - 50px);
		}
	}
`

const StyledInput = styled.input`
  margin: 4px 0 10px;
  border: ${({ theme }) => "3px solid" + theme.colors.stringWhite};
  height: 60px;
  border-radius: 10px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.fontSizes.f14};
  font-weight: ${({ theme }) => theme.font.thin};
  outline: none;
  display: block;
  padding: 15px 30px;
  width: 100%;
  position: relative;
  
  &:not(output):-moz-ui-invalid {
    box-shadow: none;
  }
  

}
  
  &::placeholder {
  color: ${({ theme }) => theme.colors.stringWhite};
  text-transform: uppercase;
  font-size:  ${({ theme }) => theme.fontSizes.f14};
  font-weight: ${({ theme }) => theme.font.thin};
  opacity: 0.4;
  }
  
  &:focus {
  color:  ${({ theme }) => theme.colors.stringWhite};
  opacity: 1;
    &::placeholder {
      //opacity: 0.5;
      // color:  ${({ theme }) => theme.colors.stringBlue};
    }
  }
  &.inputCareer, &.inputCareerMessage, &.newMessageClass{

    &:focus {
    // background-color: ${({ theme }) => theme.colors.stringWhite};
    color:  ${({ theme }) => theme.colors.stringWhite};
    opacity: 1;
      &::placeholder {
        //opacity: 0.5;
        // color:  ${({ theme }) => theme.colors.stringBlue};
      }
    }
  }
  
  &.invalid {
    border: 2px solid red !important;
  }
`

const StyledErrorMessage = styled.div`
  color: red;
  font-size:11px;
  font-weight: 300;
  margin-top: -12px;
  margin-bottom: -5px;
`

const FormInput = (props) => {
	const { isTextarea, errorMessage } = props
	return (
		<StyledLabel {...props}>
			{props.label}
			{isTextarea && <StyledInputWrapperTextarea  {...props}><StyledInput as="textarea" {...props}/></StyledInputWrapperTextarea>}
			{!isTextarea &&<StyledInputWrapper {...props}> <StyledInput {...props} /></StyledInputWrapper>}
			{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
		</StyledLabel>
	)
}

export default FormInput
