import React from "react"
import styled from "styled-components"
import { ButtonWhiteBlackBackground } from "../common/buttons/Button"
import getSingleImage from "../../utils/getSingleImage"
import StyledLink from "../common/buttons/ButtonLinkStyle"
import getSlug from "../../utils/getSlug"

const BuyWrapper = styled.div`
  display: flex;
  flex-flow: row;

  @media (max-width: 866px){
    flex-flow: column
  };
`;

const BuyField = styled.div`
  width: 100%;
  background-color: #F3F3F3;
  text-align: right;
  // height: 100vh;

  @media (max-width: 800px){
    min-height: 379px;
  };
`;

const BuyBox = styled.div`
  min-height: 690px;
  width: calc(100% - 618px);
  padding: 149px 0 0 137px;
  z-index: 1;
  background-color: #F3F3F3;


  @media (max-width: 1400px){
    padding: 149px 36px 0 36px;
  }
  @media (max-width: 866px){
    padding: 68px 36px 0 36px;
    min-height: 271px;
    width: 100%;
  }
`;

const BuyHeader = styled.div`
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.stringBlue};
  font-size: ${({ theme }) => theme.headers.h2};
  padding:	0 0 27px 0;
  width: 80%;

  @media (max-width: 1216px){
    font-size: ${({ theme }) => theme.headers.h4};
  };
  @media (max-width: 1076px){
    font-size: ${({ theme }) => theme.headers.h5};
  };
  @media (max-width: 1024px){
    font-size: ${({ theme }) => theme.headers.h6};
  };
`;

const BuySubtitle = styled.div`
  font-weight: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.stringBlue};
  font-size: ${({ theme }) => theme.headers.h9};
  padding: 0 0 86px 0;
`;

const BuyButton = styled(ButtonWhiteBlackBackground)`
  border: 3px solid ${({ theme }) => theme.colors.stringBlue};
  color: ${({ theme }) => theme.colors.stringBlue};
  min-width: 265px !important;

  :before{
    background-color: #F3F3F3;
  }
  :hover{
    background-color: ${({ theme }) => theme.colors.stringBlue};
    color: ${({ theme }) => theme.colors.stringWhite};
  }
  :hover :before{
    background-color: ${({ theme }) => theme.colors.stringWhite};
  }

`
const BuyFieldback = styled.div`
  background-image: url(${props => props.back});
	background-position: right;
	background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 717px;
  background-color: #F3F3F3;

  @media (max-width: 463px){
    min-height: 600px
  }
`

class WontToBuy extends React.Component {
  render() {
		const activeSite = this.props.activeSite
    const BuyData = this.props.data.produktJestesZainteresowanyKupnem || []
    const BuyArray = []

    BuyData.forEach(item => {
      BuyArray.push({
        "header": (item && item.naglowek || ""),
        "description": (item && item.opis || ""),
        "buttonDescription": (item && item.przyciskTekst || ""),
        "buttonSlug": (item && getSlug(item.przyciskLink[0].slug, activeSite) || ""),
        "imageUrl": (item && item.zdjecie || ""),
      })
    })

    return (

      <BuyWrapper>
        {BuyArray.map((buy, key) =>
        <BuyBox key={key}>
            <BuyHeader className="font-size-p3 font-weight-bold color-white">{buy.header}</BuyHeader>
            <BuySubtitle className="font-weight-regular color-black font-size-h9">{buy.description}</BuySubtitle>
            <StyledLink to={`/${buy.buttonSlug}`}>
              <BuyButton>{buy.buttonDescription}</BuyButton>
            </StyledLink>
        </BuyBox>
        )}
        {BuyArray.map((buy, key) =>
          <BuyFieldback key={key} back={ getSingleImage(buy.imageUrl)} />

        )}
      </BuyWrapper>
    )
  }
}
export default WontToBuy
