import React from "react"
import styled from "styled-components"
import PageContentExtendedContent from "./pageContentExtendedContent"


const Wrapper = styled.div`
	width: 100vw;
`
const PageContentWrapper = styled.div`
	position: relative;
	padding: 0 35px;
	
	@media (min-width: 1000px) {
		padding: 0 200px;
	}
	@media (min-width: 1400px) {
		padding: 0 335px 0;
	}
`


class PageContent extends React.Component {
	render() {
		const data = this.props.data || []

		return (
			<Wrapper>
				<PageContentWrapper>
					<PageContentExtendedContent data={data}/>
				</PageContentWrapper>
			</Wrapper>
		)
	}
}

export default PageContent
