import React from "react"
import styled from "styled-components"

const Infographic = styled.img`
	width: 100%;
`

class ProductInfograpghic extends React.Component {
	state = {
		isInfographicMobile: false,
	}

	constructor(props) {
		super(props)
		this.checkDevice = this.checkDevice.bind(this)
	}

	checkDevice() {
		if (window.innerWidth < 768) {
			this.setState({ isInfographicMobile: true })
		} else {
			this.setState({ isInfographicMobile: false })
		}
	}

	componentDidMount() {
		this.checkDevice()
		window.addEventListener("resize", this.checkDevice)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.checkDevice)
	}

	render() {

		const infographicData = (this.props.data && this.props.data.produktInfografika && this.props.data.produktInfografika[0]) || ""
		const infographicUrl = (infographicData.infografika && infographicData.infografika[0] && infographicData.infografika[0].url) || ""
		const infographicUrlMobile = (infographicData.infografikaMala && infographicData.infografikaMala[0] && infographicData.infografikaMala[0].url) || ""

		return (
			<Infographic src={this.state.isInfographicMobile ? infographicUrlMobile : infographicUrl} alt={this.state.isInfographicMobile ? infographicUrlMobile[0].altText : infographicUrl[0].altText}/>
		)
	}
}

export default ProductInfograpghic
