import React from "react"
import styled from "styled-components"
import getSlug from "../../utils/getSlug"
import { Link } from "gatsby"

const SeeAlsoGeneralWrapper = styled.div`
    height: 296px;
    display: flex;
    flex-flow: row nowrap;


    a:last-child {
        justify-content: center;
        align-items: flex-start;
        padding: 0 0 0 48px;
        background-color: ${({ theme }) => theme.colors.stringGrey};
        text-align: left;

        @media (max-width: 562px){
            padding: 0 36px 0 27px;
            margin: 0
        }
    };
    
    a:last-child div:first-child {
        text-align: left;
        
    };
    a:last-child div:last-child {
        text-align: left;
        
    };
    
    @media (max-width: 562px){
        height: 219px;
    }


`
const SeeAlsoBox = styled.a`
    width: 50%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.stringBlack};
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 48px 0 0;
    cursor: pointer;
    text-decoration: none;

    @media (max-width: 562px){
        padding: 0 27px 0 0;
    }
`

const SeeAlsoTitle = styled.div`
    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: ${({ theme }) => theme.headers.h5s};
    color: ${({ theme }) => theme.colors.stringWhite};
    position: relative;
    padding: 0 37px 0 0;
    height: 126px;
    text-align: right;
    width: auto;

    :before{
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: ${({ theme }) => theme.colors.stringWhite};

        @media (max-width: 562px){
            display: none
        }
    };
    @media (max-width: 562px){
        font-size: ${({ theme }) => theme.headers.h9};
        padding: 0;
        height: 50px;
    };
    @media (max-width: 375px){
        width: 80%
    }
`


const SeeAlsoSubtitle = styled.div`
    color: ${({ theme }) => theme.colors.stringWhite};
    font-weight: ${({ theme }) => theme.font.light};
    font-size: ${({ theme }) => theme.headers.h10h};
    margin: 0 0 11px 0;
    padding: 0 37px 0 0;
    width: auto;
    text-align: right;
    line-height: 1.5;
    height: 50px;

    @media (max-width: 562px){
        font-size: ${({ theme }) => theme.headers.h10};
        padding: 0;
        margin: 0 0 23px 0;
    }
    @media (max-width: 360px){
        font-size: ${({ theme }) => theme.headers.h10};
        padding: 0;
        margin: 0 0 23px;
    }
`


class SeeAlso extends React.Component {
    render() {
    	const activeSite= this.props.activeSite

        const SeeAlsoData = (this.props.data && this.props.data.zobaczRowniez) || this.props.zobaczRowniez || [];
        const seeAlsoArray = [];

        SeeAlsoData.forEach(item => {
        	if(item.__typename === "Craft_produkty_produkty_Entry") {
						seeAlsoArray.push({
							"slug": getSlug(item.slug, activeSite),
							"subtitle": (item?.produktWizytowka?.[0] && item?.produktWizytowka?.[0].dopisek) || "",
							"title": (item?.produktWizytowka?.[0] && item?.produktWizytowka?.[0].nazwaProduktu) || "",
						})
					}
        	else {
						seeAlsoArray.push({
							"slug": getSlug(item.slug, activeSite),
							"subtitle": (item?.wizytowkaPodstrona?.[0] && item?.wizytowkaPodstrona?.[0].opis) || "",
							"title": (item?.wizytowkaPodstrona?.[0] && item?.wizytowkaPodstrona?.[0].nazwa) || "",
						})
					}
        })

        return (
            <SeeAlsoGeneralWrapper >
            {seeAlsoArray.map((seeAlso, key) =>
            <SeeAlsoBox as={Link} to={`/${seeAlso.slug}`} key={key}>
                <SeeAlsoSubtitle>{seeAlso.subtitle}</SeeAlsoSubtitle>
                <SeeAlsoTitle>{seeAlso.title}</SeeAlsoTitle>
            </SeeAlsoBox>
            )}
            </SeeAlsoGeneralWrapper>

        )
    }
}
export default SeeAlso
