import React from "react"
import styled from "styled-components"
import getSingleImage from "../../utils/getSingleImage"

const PhotoWrapper = styled.div`
	padding-top: 60px;
	padding-bottom: 20px;
	&.wide {
		@media (min-width: 1000px) {
			margin-left: -120px;
			margin-right: -120px;
		}
	}
`

const PhotoStyled = styled.img`
	display: flex;
	width: 100%;
`

class Photo extends React.Component {
	render() {
		const data = (this.props.data) || []
		const photoWide = data.zdjecie || ""

		return (
			<PhotoWrapper className={data.__typename === "Craft_TrescPodstronyZdjecieSzerokie" ? "wide" : ""}>
				<PhotoStyled src={getSingleImage(photoWide)} alt={photoWide[0].altText}/>
			</PhotoWrapper>

		)
	}
}

export default Photo
