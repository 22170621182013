import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import getSingleImage from "../../utils/getSingleImage"
import { connect } from "react-redux"
import { changeLogoColor } from "../../state/app"


const WrapperElement = styled.div`
  height: 100%;
  padding: 150px 0 0 0;

  @media (max-width: 999px){
    padding: 77px 0 0 0
  }
`
const Career = styled.div`
	width: 100%;
	min-height: 900px;
	background-color: black;
	color: ${({ theme }) => theme.colors.stringWhite};
	padding: 216px 318px 256px 318px;
	position: relative;
	
	@media (max-width: 1400px){
		padding: 216px 138px 256px 138px	
	};
	@media (max-width: 999px){
		padding: 216px 36px 256px 36px	
	};
	@media (max-width: 600px){
		padding: 83px 36px 143px 36px		
	};
	@media (max-width: 500px){
		min-height: 406px;
	};
`

const CareerDescription = styled.div`
  width: 50%;
	color: ${({ theme }) => theme.colors.stringWhite};
  font-size: ${({ theme }) => theme.fontSizes.f13};
	font-weight: ${({ theme }) => theme.font.regular};
	line-height: 1.8;
	margin: 67px 0 109px 0;
	position: relative;
	z-index: 3;
	opacity: 1;

	p{
		padding: 0;
		width: 100%;
	};
	
	@media (max-width: 600px){
		width: 100%;
		font-size: ${({ theme }) => theme.fontSizes.f23};
		margin: 60px 0 50px 0
	};
	@media (max-width: 500px){
		font-size: ${({ theme }) => theme.fontSizes.f14};
		margin: 28px 0
	}
`

const CareerHeader = styled.div`
	font-size: ${({ theme }) => theme.headers.h2};
	font-weight: ${({ theme }) => theme.font.bold};
	position: relative;	
	width: fit-content;
	z-index: 1;
	padding: 0 31px 0 0;
	color: ${({ theme }) => theme.colors.stringWhite}; 
	margin: 0;
	
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		width: 8px;
		height: 8px;
		right: -10px;
		background-color: ${({ theme }) => theme.colors.stringWhite}; 
	}

	@media (max-width: 500px){
		font-size: ${({ theme }) => theme.headers.h6};
		margin: 87px 31px 0 0;

		&:before {
			top: 0; 
		}
	}

`

const BackgroudndImage = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0
`
const Image = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${props => props.back});
	opacity: .4;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border: 0;
	padding: 0;
	margin: 0
`


class CareerBanner extends React.Component {

    render() {
				const { dispatch } = this.props
				dispatch(changeLogoColor(true))
        const CareerBannerData = (this.props.data && this.props.data.banerZTekstemIZdjeciem) || []
        const CareerBannerArray = []

        CareerBannerData.forEach(item => {
            CareerBannerArray.push({
                "header": (item && item.naglowek || ""),
                "imageUrl": (item && item.zdjecie || ""),
                "description": (item && item.opis || "")
            })
		})

		return (
			<Career>
				<BackgroudndImage>
					{CareerBannerArray.map((career, key) =>
						<Image key={key} back={getSingleImage(career.imageUrl)} />

					)}
				</BackgroudndImage>
				{CareerBannerArray.map((career, key) =>
					<WrapperElement key={key}>
						<CareerHeader>{career.header}</CareerHeader>
						<CareerDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(career.description)}></CareerDescription>
					</WrapperElement>
				)}
			</Career>
		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(CareerBanner)
