import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"

const FooterBoxesContact = styled.div`
	padding: 0 0 45px 0;
	line-height: 77px;
	margin: 0 157px 0 0;
	height: auto;
	
	@media (max-width: 970px){
		margin: 0 30px 0 0;
	};

	@media (max-width: 375px){
			width: 100%;
	};
`

const FooterHeader = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.headers.h7};
	color: ${({ theme }) => theme.colors.stringWhite};

	@media (max-width: 800px){
			padding: 0 0 0 0
	};
`

const ULLIst = styled.ul`
	font-weight: ${({ theme }) => theme.font.regular};
	// font-size: ${({ theme }) => theme.headers.h11};
	color: ${({ theme }) => theme.colors.stringWhite};
	list-style-typne: none;
	padding: 0;
	margin: 0;
	line-height: 2;

	@media (max-width: 800px){
			padding: 0 0 0 0
	};
`
const ListItem = styled.li`
	margin: 30px 0 0 0;
`

class ColumnOne extends React.Component {
	render() {
		const columnOneData = this.props.columnOneData
		const firstColumn = []
		columnOneData.forEach(item => {
			firstColumn.push({
				"naglowek": item.naglowek || "",
				"daneFirmy": item.daneFirmy || "",
				"daneKontaktowe": item.daneKontaktowe || "",
			})
		})

		return (
			firstColumn.map((footer, key) =>
				<FooterBoxesContact key={key}>
					<FooterHeader>{footer.naglowek}</FooterHeader>
					<ULLIst>
						<li dangerouslySetInnerHTML={getHtmlFromRedactorField(footer.daneFirmy)}/>
						<ListItem dangerouslySetInnerHTML={getHtmlFromRedactorField(footer.daneKontaktowe)}/>
					</ULLIst>
				</FooterBoxesContact>,
			)
		)
	}
}

export default ColumnOne
