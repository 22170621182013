import React from "react"
import styled from "styled-components"
import Slider from "react-slick/lib"
import HomeSlide from "./HomeSlide"
import { connect } from "react-redux"
import { changeLogoColor } from "../../state/app"
import UELogo from "../../images/fundusze-unijne-string.webp"

const settings = {
	infinite: true,
	arrows: false,
	speed: 900,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 5000,
	pauseOnHover: false,
	dots: false,
	fade: true,
	vertical: true,
	beforeChange: () => {
		const allSlideImg = document.querySelectorAll(".slick-slide .slide-image-wrapper")
		const allSlideContent = document.querySelectorAll(".slick-slide .slide-content")
		allSlideImg.forEach(i => i.classList.remove("animate"))
		allSlideContent.forEach(i => i.classList.remove("animate"))
		const slideImg = document.querySelector(".slick-current .slide-image-wrapper")
		const slideContent = document.querySelector(".slick-current .slide-content")
		slideImg.classList.add("animate")
		slideContent.classList.add("animate")
	},
}

const WhiteStripeWrapper = styled.div`
 width: 100vw;
 position: absolute;

 left: 0;
 right: 0;
  background-color: ${({ theme }) => theme.colors.stringWhite};
 height: 70px;
 z-index: 998;
 border-top: 1px solid #293BDC;
 
		top: unset;
		bottom: 0;

	img {
		height: auto;
		max-width: 100vw;
		margin: 4px auto 0;
		display: flex;
	}
	
	@media (min-width: 500px){ 
		img {
			height: 59px;
		}
	}
	
	@media (min-width: 1000px){
		 top: calc(${({ sliderHeight }) => sliderHeight} - 70px);
		img {
			height: 59px;
			margin-left: 130px;
			margin-top: 4px;
		}
	}
`
const HomeSliderWrapper = styled.div`
	width: 100%;
	display: block;
	position: relative;
	margin-left: 0;
	min-height: calc(100vh + 130px);
		
	@media (min-width: 975px){ 
		min-height: auto;
	}
	@media (min-width: 1000px){ 
		position: static;
		display: inline-block;
	}
	
	.slick-list {
		min-height: 800px;
	}
	.slick-dots {
		text-align: start;
		//bottom: 20px;
		bottom: 100px;
		width: 100px;
		
		li {
			left: 35px;
			button:before {
				content: '';
				width: 11px;
				height: 11px;
				background-color: ${({ theme }) => theme.colors.squareGray};
				opacity: 1;
			}
			&.slick-active {
				bottom: 12px;
				button:before {
					background-color: ${({ theme }) => theme.colors.stringBlue};
				}
			}
			
			@media (min-width: 1400px){ 
				left: 140px;
			}
		}
}`


const SliderBackground = styled.div`
	display: none;
	
	@media (min-width: 1000px){ 
		display: block;
		position: absolute;
		top: 0;
		left: 930px;
		background-color: ${({ theme }) => theme.colors.stringBlue};
		width: 400px;
		height: 100vh;
		min-height: 759px;
	}
	
	@media (min-width: 1400px){ 
		width: 100%;
		max-width: 560px;
	}
	 
	@media (min-width: 1560px){
		right: 0;
		left: unset;
		width: 560px;
		max-width: unset;
	
	@media (min-width: 1900px){
		min-height: 820px;
	}
	@media (min-width: 2000px){
		width: 630px;
	}
	@media (min-width: 2074px){
		min-height: 870px;
	}
	 @media (min-width: 2260px){
		width: 735px;
	}
`

class HomeSlider extends React.Component {

	state = {
		sliderHeight: 0
	}

	animate() {
		const children = document.querySelector(".slick-current .slide")
		const slideContent = document.querySelector(".slick-current .slide-content")
		children.classList.add("animate")
		slideContent.classList.add("animate")
	}

	componentDidMount() {
		this.setSliderHeight()
		window.addEventListener('resize', this.setSliderHeight);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setSliderHeight);

	}

	setSliderHeight = () => {
		const sliderEl = document.getElementById('slider-wrapper')
		const sliderElHeight = sliderEl ? sliderEl.clientHeight : 0;
		this.setState({ sliderHeight: sliderElHeight })
	}

	render() {
		const { dispatch, activeSite } = this.props
		dispatch(changeLogoColor(false))
		const slides = this.props.data.slider



		return (
			<div>
			<HomeSliderWrapper id="slider-wrapper">
				<SliderBackground/>
				<Slider {...settings}>
					{slides.map((slide, key) => <HomeSlide key={key} slide={slide} animate={this.animate} activeSite={activeSite}/>)}
				</Slider>

				<WhiteStripeWrapper sliderHeight={`${this.state.sliderHeight}px`}>
					<img src={UELogo} alt={UELogo[0].altText}/>
				</WhiteStripeWrapper>
			</HomeSliderWrapper>
			</div>
		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(HomeSlider)
