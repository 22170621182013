import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"

const ColumnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	font-size: ${({ theme }) => theme.fontSizes.f14};
	line-height: ${({ theme }) => theme.lineHeight.line7};
	font-weight:  ${({ theme }) => theme.font.regular};
	color: ${({ theme }) => theme.colors.stringBlack};
	margin-top: 40px;
	
	@media (min-width: 1000px) {
		flex-direction: row;
		margin-top: 0;
		
		div {
			width: 100%;
		}
	}
	
	@media (min-width: 1400px) {
		padding-bottom: 70px;
		margin-left: -120px;
		margin-right: -120px;
	}
`

const Column = styled.div`
	@media (min-width: 1000px) {
		&:first-child {
			padding-right: 25px;
		}
			&:last-child {
			padding-left: 25px;
		}
	}
`

class NewsColumns extends React.Component {
	render() {
		const data = (this.props.data) || []
		const firstColumn = data.kolumna || {}
		const secondColumn = data.drugaKolumna || {}

		return (
			<ColumnsWrapper>
				<Column dangerouslySetInnerHTML={getHtmlFromRedactorField(firstColumn)}/>
				<Column dangerouslySetInnerHTML={getHtmlFromRedactorField(secondColumn)}/>
			</ColumnsWrapper>
		)
	}
}

export default NewsColumns
