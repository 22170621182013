import React from "react"
import styled from "styled-components"
import getSingleImage from "../../utils/getSingleImage"
import Paragraphs from "../common/paragraphs/Paragraphs"
import { changeLogoColor } from "../../state/app"
import { connect } from "react-redux"

const ProductBannerWrapper = styled.div`
	height: 100%;
`
const ProductBannerContent = styled.div`
	display: flex;
	min-height: 759px;
	flex-direction: column-reverse;
	justify-content: flex-end;
	height: 100vh;
	width: 100vw;
	position: relative;
	padding: 0 35px;
	background-color:${({ theme }) => theme.colors.lightGray};

	@media (min-width: 1000px) {
		flex-direction: row;
		justify-content: flex-start;
	}
`

const BannerBackground = styled.div`
	display: none;
	
	@media (min-width: 1000px){ 
		display: block;
		position: absolute;
		top: 0;
		left: 930px;
		background-color: ${({ theme }) => theme.colors.stringWhite};
		width: 400px;
		height: 100vh;
		min-height: 759px;
		z-index: 0;
	}
	
	@media (min-width: 1400px){ 
		width: 100%;
		max-width: 560px;
	}
	 
	@media (min-width: 1560px){
		right: 0;
		left: unset;
		width: 560px;
		max-width: unset;
	
	@media (min-width: 2000px){
		width: 630px;
	}
	 @media (min-width: 2260px){
		width: 735px;
	}
`

const MobileImgWrapper = styled.div`
	position: relative;
	margin-top: 200px;
	
	@media (min-width: 1000px) {
		position:unset;
	}
`

const TextBg = styled.div`
	color: ${({ theme }) => theme.colors.stringWhite};
	font-weight: ${({ theme }) => theme.font.semiBold};
	position: absolute;
	z-index: 0;
	pointer-events: none;
`

const MobileBg = styled(TextBg)`
	top: -120px;
	left: 50%;
	transform:translateX(-50%);
	font-size: ${({ theme }) => theme.fontSizes.f21};
	
	@media (min-width: 1000px) {
			display: none;
	}
`

const DesktopBg = styled(TextBg)`
	display: none;

	@media (min-width: 1000px) {
		display: block;
		font-size: ${({ theme }) => theme.fontSizes.f22};
		top: -45px;
		left: -200px;
	}
	@media (min-width: 1840px){ 
		left: -430px;
	}
	@media (min-width: 2000px){ 
		left: -560px;
	}
	@media (min-width: 2350px){ 
		left: -1000px;
	}
`

const TextWrapper = styled.div`
	position: relative;
	
	@media (min-width: 1000px) {
		padding-left: 105px;
	}
`

const MainHeader = styled.h1`
	font-size: ${({ theme }) => theme.headers.h6};
	font-weight: ${({ theme }) => theme.font.black};
	position: relative;	
	width: fit-content;
	z-index: 1;
		&.color-blue {
		color: ${({ theme }) => theme.colors.stringBlue};
	}
	
	@media (min-width: 1000px) {
		margin-top: 300px;
		font-size: ${({ theme }) => theme.headers.h1h};
		font-weight: ${({ theme }) => theme.font.semiBold};
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		width: 8px;
		height: 8px;
		right: -10px;
		background-color: ${({ theme }) => theme.colors.stringBlue};
	
		@media (min-width: 1000px) {
			width: 23px;
			height: 23px;
			right: -80px;
		}
	}
`

const Description = styled(Paragraphs)`
	padding-top: 15px;
	font-size: ${({ theme }) => theme.paragraphs.p2};

	@media (min-width: 1000px) {
		padding-top: 30px;
		font-size: ${({ theme }) => theme.paragraphs.p1};
		width: 460px;
	}
	@media (min-width: 1370px) {
		padding-top: 30px;
		font-size: ${({ theme }) => theme.paragraphs.p1};
	}
	@media (min-width: 1560px) {
		width: auto;
		margin-right: 1000px;
	}
	@media (min-width: 2200px) {
		width: auto;
		margin-right: 1200px;
	}
`
const ProductImgWrapper = styled.div`
	position: relative;
	width: 100%;
	
	@media (min-width: 1000px) {
		margin: 60px 0 0;
		position: absolute;
		left: 550px;
		width: 830px;
	}
	@media (min-width: 1560px){ 
		left: unset;
		right: 30px;
	}	
	@media (min-width: 1710px){ 
		right: 50px;
	}
	@media (min-width: 1840px){ 
		//right: 250px;
		right: 210px;
	}
	@media (min-width: 2260px){ 
		right: 350px;
	}		
		@media (min-width: 2350px){ 
		right: 450px;
	} 
	@media (min-width: 2560px){ 
		right: 250px;
		width: auto;
	}
`

const ProductImg = styled.img`
	position: relative;
	z-index: 1;
	width: calc(100vw - 70px);
	max-width: 562px;
	left: 50%;
	transform: translateX(-50%);
		
	@media (min-width: 1000px) {
		left: 0;
		transform: none;
		width: 830px;
		max-width: 100%;
	}
	@media (min-width: 1560px){ 
		//width: 60vw;
		width: 100%;
	}
`

class ProductBanner extends React.Component {

	render() {
		const { dispatch } = this.props
		dispatch(changeLogoColor(false))

		const productData = (this.props.data && this.props.data.produktBaner && this.props.data.produktBaner[0]) || ""
		const productName = productData.naglowek
		const productDescription = productData.opis
		const productImg = productData.zdjecieProduktu || ""

		return (

			<ProductBannerWrapper>
				<ProductBannerContent>
					<BannerBackground/>
					<TextWrapper>
						<MainHeader className="color-blue">{productName}</MainHeader>
						<Description
							className="color-black font-weight-light font-family-subheader">{productDescription}</Description>
					</TextWrapper>
					<MobileImgWrapper>
						<MobileBg>{productName}</MobileBg>
						<ProductImgWrapper>
							<DesktopBg>{productName}</DesktopBg>
							<ProductImg src={getSingleImage(productImg)} alt={productImg[0].altText}/>
						</ProductImgWrapper>
					</MobileImgWrapper>
				</ProductBannerContent>
			</ProductBannerWrapper>
		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite
}), null)(ProductBanner)
