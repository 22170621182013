import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"

const CareerContent = styled.div`
	background-color: ${({ theme }) => theme.colors.lighterGrey};
	min-height: 371px;
	margin: 0;
	display: flex; 
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 138px;

	@media (min-width: 1327px){
		padding: 0 0 0 336px;
	}

	@media (max-width: 800px){
		padding: 0 36px;
	};
`

const CareerHeader = styled.div`
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.headers.h2};
	padding: 0 0 45px 0;
	width: 100%;
	
	@media (max-width: 800px){
		font-size: ${({ theme }) => theme.headers.h6};
		padding: 0 0 83px 0;
		line-height: 50px;
		text-align: left;
	};

	@media (max-width: 400px){
		padding: 0 0 42px 0;
		width: 65%;
	};
	@media (max-width: 375px){
		padding: 0 0 42px 0;
		width: 70%;
	};
`

const CareerText = styled.div`
	font-weight: ${({ theme }) => theme.font.light};
	font-size: ${({ theme }) => theme.paragraphs.p2};
	color: ${({ theme }) => theme.colors.stringBlack};
	width: 55%;

	@media (max-width: 800px){
		width: 100%;
		text-align: left;
	};
`


class CareerHeaderAndDescription extends React.Component {
    render() {
			const CarreData = this.props.data.naglowekIOpis;
			const CarreHeaderaboutData = this.props.data.naglowekIOpis[0].naglowek;
			const CarreOpisboutData = this.props.data.naglowekIOpis[0].opis;


        return (
            <CareerContent>
						<CareerHeader>{CarreHeaderaboutData}</CareerHeader>
						<CareerText dangerouslySetInnerHTML={getHtmlFromRedactorField(CarreOpisboutData)} />
            </CareerContent>

        )
    }
}

export default CareerHeaderAndDescription
