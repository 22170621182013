import React from "react"
import styled from "styled-components"


const ButtonWrapper = styled.div`
	height: 60px;
	width: 185px;
	border-radius: 7px;
	color: ${({ theme }) => theme.colors.stringBlue};
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.paragraphs.p2};
	background-color: transparent;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	padding: 0;
	position: absolute;


	:hover{
		background-color: ${({ theme }) => theme.colors.stringWhite};
	};
	:hover .left{
			Width: 16px;
	};
	:hover .right{
			Width: 155px;
	};
	:hover .content{
			color: ${({ theme }) => theme.colors.stringBlue};
	};

`

const LeftPart = styled.div`
		border-top: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-left: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-bottom: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-top-left-radius: 7px;
		border-bottom-left-radius: 7px;
		width: 155px;
		height: 104%;
		margin: 0;
		padding: 0;
		position: absolute;
		top:0;
		left: 0;
		transition: 0.5s linear;
		z-index: 0;
		`
const RightPart = styled.div`
		z-index: 0;
		border-top: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-right: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-bottom: 3px solid ${({ theme }) => theme.colors.stringWhite};
		border-top-right-radius: 7px;
		border-bottom-right-radius: 7px;
		width: 16px;
		height: 104%;
		margin: 0;
		padding: 0;
		position: absolute;
		top:0;
		right: 0;
		transition: 0.5s linear;


		:before{
				content: "";
				width: 14px;
				height: 3px;
				background-color: ${({ theme }) => theme.colors.stringWhite};
				position: absolute;
				top: -3px;
				left: -14px;

		}
`

const ButtonContent = styled.div`
	color: ${({ theme }) => theme.colors.stringWhite};
	position: absolute;
	top: 50%;
	//left: 61px;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	width: auto;
	height: auto;
	background-color: transparent;

`

class ButtonOnPictureNews extends React.Component {
	render() {
		const { readTextBtn } = this.props

		return (

			<ButtonWrapper className={this.props.className}>
				<LeftPart className="left"/>
				<RightPart className="right"/>
				<ButtonContent className="content">{readTextBtn}</ButtonContent>
			</ButtonWrapper>

		)
	}
}


export default ButtonOnPictureNews
