import React from "react"
import styled from "styled-components"
import InternalLink from "../common/InternalLink"
import getSlug from "../../utils/getSlug"

const FooterBoxes = styled.div`
  padding: 0 0 45px 0;
  line-height: 77px;
  height: auto;
`

const FooterHeader = styled.div`
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.headers.h7};
  color: ${({ theme }) => theme.colors.stringWhite};

  @media (max-width: 800px){
      padding: 0 0 0 0
  };
`

const ULLIst = styled.ul`
  font-weight: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.stringWhite};
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 2;

  @media (max-width: 800px){
      padding: 0 0 0 0
  };
`

class ColumnTwo extends React.Component {
	render() {
		const {columnTwoData, activeSite} = this.props
		const pages = columnTwoData[0].podstrony
		const secondKolumn = []
		const secondKolumnContent = []
		columnTwoData.forEach(item => {
			secondKolumn.push({
				"naglowek": item.naglowek || "",
			})
		})

		pages.forEach(item => {
			secondKolumnContent.push({
				"slugTitle": item.title || "",
				"slug": getSlug(item.slug, activeSite) || "",
			})
		})
		return (
			<FooterBoxes>
				{secondKolumn.map((footer, key) =>
					<FooterHeader key={key}>{footer.naglowek}</FooterHeader>,
				)}
				{secondKolumnContent.map((footerContent, key) =>
					<ULLIst key={key}>
						<InternalLink to={footerContent.slug}>{footerContent.slugTitle}</InternalLink>
					</ULLIst>,
				)}
			</FooterBoxes>
		)
	}
}

export default ColumnTwo
