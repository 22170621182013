import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"

const DescriptionStyled = styled.div`
	padding: 30px 0;
	font-size: ${({ theme }) => theme.fontSizes.f14};
	line-height: ${({ theme }) => theme.lineHeight.line7};
	font-weight: ${({ theme }) => theme.font.light};
	color: ${({ theme }) => theme.colors.stringBlack};
	display: flex;

	@media (min-width: 1000px) {
		padding: 50px 0;
		font-weight: ${({ theme }) => theme.font.regular};
	}
	
	ul {
	padding-left: 15px;
		li {
			list-style: square;
		}
	}
`

class Description extends React.Component {
	render() {
		const data = (this.props.data) || []
		const description = data.opis || ""

		return (
			<DescriptionStyled dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
		)
	}
}

export default Description
